import { Button, Descriptions, Divider, Flex, Modal, Table } from "antd";
import moment from "moment";
import { useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import {
  useGetOrganizationLogoById,
  useGetPayrollById,
} from "../../../api/api.get";
import { useCurrency } from "../../../hooks/useCurrency";
import {
  IDeduction,
  IOrganization,
  IPayroll,
  IPayrollBonus,
  IPayrollCycle,
  IPayrollEmployee,
  IPayrollStub,
} from "../../../types/types";
import { capitalize } from "../../../utils/functions";

export default function ViewPayStubModal({
  open,
  onCancel,
  payrollId,
}: {
  payrollId?: number;
  open: boolean;
  onCancel: () => void;
}) {
  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({
    contentRef,
  });

  const { id } = useParams();
  const { data: orgLogoData } = useGetOrganizationLogoById(id);
  const { data: payroll } = useGetPayrollById(id, payrollId);

  const totalBonus = useMemo(() => {
    if (payroll && payroll && payroll.bonuses) {
      const total = payroll.bonuses.reduce((acc, bonus) => {
        if (bonus.bonusType === "flat") {
          return bonus.amount + acc;
        }
        return acc + (bonus.amount / 100) * payroll.amount;
      }, 0);
      return total;
    }
    return 0;
  }, [payroll]);

  const totalDeduction = useMemo(() => {
    if (payroll && payroll && payroll.deductions) {
      const total = payroll.deductions.reduce((acc, deduction) => {
        if (deduction.type === "flat") {
          return deduction.value + acc;
        }
        return acc + (deduction.value / 100) * payroll.amount;
      }, 0);
      return total;
    }
    return 0;
  }, [payroll]);

  const grossSalary = useMemo(() => {
    if (payroll && totalBonus !== undefined) {
      return payroll.amount + totalBonus;
    }
    return 0;
  }, [payroll, totalBonus]);

  return (
    <Modal
      title="Pay Statement"
      open={open}
      onOk={onCancel}
      onCancel={onCancel}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
      footer={null}
      width={800}
    >
      <Flex gap={10} vertical className="my-20">
        <div className="divRight">
          <Button onClick={() => handlePrint()} type="link">
            Print
          </Button>
        </div>

        <div
          id="payroll-stub-container"
          ref={contentRef}
          className="p-40"
          style={{ marginTop: 40 }}
        >
          {orgLogoData?.logo && (
            <img
              src={orgLogoData.logo}
              alt="logo"
              width={75}
              style={{ borderRadius: 20 }}
            />
          )}

          <div className="divSpread my-10">
            <OrganizationDetailsSection org={payroll?.organization} />

            <StatementDetailsSection payroll={payroll} cycle={payroll?.cycle} />
          </div>

          <Divider />

          <EmployeeDetailsSection employee={payroll?.employee} />
          <Divider />

          <SalaryDetailsSection
            totalBonuses={totalBonus.toFixed(2)}
            totalDeductions={totalDeduction.toFixed(2)}
            grossSalary={grossSalary.toFixed(2)}
            payroll={payroll}
          />

          <BonusesSection bonuses={payroll?.bonuses} payroll={payroll} />

          <DeductionsSection
            deductions={payroll?.deductions}
            payroll={payroll}
            grossSalary={grossSalary}
          />

          <Divider />

          <SummarySection
            payroll={payroll}
            totalBonuses={totalBonus.toFixed(2)}
            totalDeductions={totalDeduction.toFixed(2)}
            grossSalary={grossSalary.toFixed(2)}
          />

          <Divider />
        </div>
      </Flex>
    </Modal>
  );
}

function OrganizationDetailsSection({ org }: { org?: IOrganization }) {
  return (
    <div className="flex-1">
      <h4>{capitalize(org?.name)}</h4>
      <div className="divColumn">
        <span>{org?.street1}</span>
        <span>{org?.street2}</span>
        <span>{capitalize(org?.city)}</span>
        <span>
          {capitalize(org?.state)}, <strong>{org?.country}</strong>{" "}
          {org?.zipCode}
        </span>
      </div>
    </div>
  );
}

function StatementDetailsSection({
  payroll,
  cycle,
}: {
  payroll?: IPayrollStub;
  cycle?: IPayrollCycle;
}) {
  return (
    <div className="flex-1 divRight">
      <div className="divColumn">
        <div className="divFlex">
          <span>Pay Start Date:</span>
          <span className="mx-10">{toDate(cycle?.startDate)}</span>
        </div>
        <div className="divFlex my-10">
          <span>Pay End Date:</span>
          <span className="mx-10">{toDate(cycle?.endDate)}</span>
        </div>
        <div className="divFlex">
          <span>Pay Statement Date:</span>
          <span className="mx-10">{toDate(payroll?.createdAt)}</span>
        </div>
      </div>
    </div>
  );
}

function toDate(date?: string) {
  if (date) {
    return moment(date).format("DD MMM YYYY");
  }
  return "-";
}

function EmployeeDetailsSection({
  employee,
  cycle,
}: {
  employee?: IPayrollEmployee;
  cycle?: IPayrollCycle;
}) {
  return (
    <div className="flex-1">
      <div className="divColumn">
        <h4>
          {capitalize(employee?.firstName) +
            " " +
            capitalize(employee?.lastName)}
        </h4>
        <div className="divColumn">
          <span>{employee?.street1}</span>
          <span>{employee?.street2}</span>
          <span>{capitalize(employee?.city)}</span>
          <span>
            {capitalize(employee?.state)}, <strong>{employee?.country}</strong>{" "}
            {employee?.zipCode}
          </span>
        </div>
      </div>
    </div>
  );
}

function SalaryDetailsSection({
  payroll,
  grossSalary,
  totalDeductions,
  totalBonuses,
}: {
  totalBonuses: string;
  totalDeductions: string;
  payroll?: IPayrollStub;
  grossSalary: string;
}) {
  const { currency } = useCurrency();

  return (
    <div className="flex-1">
      <div className="divColumn">
        <Descriptions
          bordered
          title="Salary"
          size="small"
          layout="vertical"
          items={[
            {
              label: "Employment Type",
              labelStyle: { fontSize: "x-small" },
              key: "1",
              children: (
                <span className="small-font">
                  {capitalize(payroll?.employee?.profile?.employmentType)}
                </span>
              ),
            },
            {
              label: "Employment Status",
              labelStyle: { fontSize: "x-small" },

              key: "2",
              children: (
                <span className="small-font">
                  {!payroll?.employee.profile?.employmentEndDate
                    ? "Active"
                    : "InActive"}
                </span>
              ),
            },
            {
              label: "Employment Start Date",
              labelStyle: { fontSize: "x-small" },

              key: "3",
              children: (
                <span className="small-font">
                  {toDate(payroll?.employee?.profile?.employmentStartDate)}
                </span>
              ),
            },
          ]}
        />
        <Descriptions
          size="small"
          bordered
          className="my-20"
          layout="vertical"
          items={[
            {
              label: "Employment End Date",
              labelStyle: { fontSize: "x-small" },

              key: "4",
              children: (
                <span className="small-font">
                  {toDate(payroll?.employee?.profile?.employmentEndDate)}
                </span>
              ),
            },
            {
              label: "Total Hours",
              labelStyle: { fontSize: "x-small" },
              key: "4.5",
              children: (
                <span className="small-font">{payroll?.totalHours}</span>
              ),
            },
            {
              label: "Gross Salary",
              labelStyle: { fontSize: "x-small" },

              key: "5",
              children: (
                <span className="small-font">
                  {currency} {grossSalary}
                </span>
              ),
            },
            {
              label: "Net Salary",
              labelStyle: { fontSize: "x-small" },
              key: "6",
              children: (
                <span className="small-font">
                  {currency} {payroll?.netAmount}
                </span>
              ),
            },
            {
              label: "Total Deductions",
              labelStyle: { fontSize: "x-small" },
              key: "7",
              children: (
                <span className="small-font">
                  {currency} {totalDeductions}
                </span>
              ),
            },
            {
              label: "Total Bonuses",
              labelStyle: { fontSize: "x-small" },
              key: "8",
              children: (
                <span className="small-font">
                  {currency} {totalBonuses}
                </span>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
}

function DeductionsSection({
  deductions,
  payroll,
  grossSalary,
}: {
  grossSalary: number;
  deductions?: IDeduction[];
  payroll?: IPayroll;
}) {
  const { currency } = useCurrency();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value: string) => <span>{capitalize(value)}</span>,
    },
    {
      title: "",
      key: "type",
      render: (row: IDeduction) => (
        <span>{row.type === "percent" ? `${row.value}%` : row.value}</span>
      ),
    },
    {
      title: "Total Deduction",
      key: "value",
      render: (row: IDeduction) => (
        <span>
          {currency}
          {row.type === "percent"
            ? `${((row.value / 100) * grossSalary).toFixed(2)}`
            : row.value}
        </span>
      ),
    },
  ];
  return (
    <div className="flex-1 my-20">
      <h3>Deductions</h3>
      {deductions && deductions.length > 0 && (
        <Table
          dataSource={deductions}
          columns={columns}
          pagination={{ hideOnSinglePage: true }}
        />
      )}
      {deductions?.length === 0 && <strong>-</strong>}{" "}
    </div>
  );
}

function BonusesSection({
  bonuses,
  payroll,
}: {
  bonuses?: IPayrollBonus[];
  payroll?: IPayroll;
}) {
  const { currency } = useCurrency();

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (value: string) => <span>{capitalize(value)}</span>,
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "amount",
      render: (value: number) => (
        <span>
          {currency}
          {value}
        </span>
      ),
    },
  ];
  return (
    <div className="flex-1">
      <h3>Bonuses</h3>
      {bonuses && bonuses?.length > 0 && (
        <Table
          dataSource={bonuses}
          columns={columns}
          pagination={{ hideOnSinglePage: true }}
        />
      )}
      {bonuses?.length === 0 && <strong>-</strong>}
    </div>
  );
}

export function SummarySection({
  totalBonuses,
  totalDeductions,
  grossSalary,
  payroll,
}: {
  grossSalary: string;
  payroll?: IPayroll;
  totalBonuses: string;
  totalDeductions: string;
}) {
  const { currency } = useCurrency();

  return (
    <div className="my-40">
      <h3>Pay Summary</h3>
      <div className="divColumn my-40">
        <div className="divFlex">
          <strong>Gross Pay:</strong>
          <span className="mx-10">
            {currency}
            {grossSalary}
          </span>
        </div>

        <div className="divFlex my-20">
          <strong>Total Deductions:</strong>
          <span className="mx-10">
            {currency}
            {totalDeductions}
          </span>
        </div>
        <div className="divFlex">
          <strong>Net Pay:</strong>
          <span className="mx-10">
            {currency}
            {payroll?.netAmount}
          </span>
        </div>
      </div>
    </div>
  );
}
