import {
  BankOutlined,
  DollarCircleOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  PhoneOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { Menu } from "antd";
import { useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useGetProfileOnOrganization } from "../../api/api.get";
import { capitalize } from "../../utils/functions";

export default function LeftPaneComponent() {
  const isPhase2Enabled = useFeatureIsOn("enable-business-phase2");
  const [show, setShow] = useState<boolean>(true);

  const { id: orgId } = useParams();
  const { data: profile } = useGetProfileOnOrganization(orgId);

  const businessOwnerPhase2Items = useMemo(() => {
    if (isPhase2Enabled) {
      return [
        {
          key: "2.1",
          icon: (
            <Link to={`/organizations/${orgId}/products`}>
              <img
                src={"/assets/icons/food-other.svg"}
                width={20}
                alt="products"
              />
            </Link>
          ),
          label: "Products",
        },
        {
          key: "2.2",
          icon: (
            <Link to={`/organizations/${orgId}/invoices`}>
              <img src={"/assets/icons/list.png"} width={20} alt="invoices" />
            </Link>
          ),
          label: "Invoices",
        },
      ];
    }
    return [];
  }, [isPhase2Enabled, orgId]);

  const items: any = useMemo(() => {
    if (orgId) {
      const defaultItem = [
        {
          key: "0",
          icon: (
            <div className="divRight w-100">
              <MenuFoldOutlined
                style={{ cursor: "pointer" }}
                onClick={() => setShow(false)}
              />
            </div>
          ),
        },
        {
          key: "1",
          icon: (
            <Link to={`/organizations/${orgId}`}>
              <img src={"/assets/icons/house.svg"} width={20} alt="home" />
            </Link>
          ),
          label: capitalize("home"),
        },
      ];
      if (profile?.type === "admin") {
        return [
          ...defaultItem,
          ...businessOwnerPhase2Items,
          {
            key: "2",
            icon: (
              <Link to={`/organizations/${orgId}/sales`}>
                <img src={"/assets/icons/profits.svg"} width={20} alt="sales" />
              </Link>
            ),
            label: "Orders",
          },
          {
            key: "3",
            icon: (
              <Link to={`/organizations/${orgId}/payroll-cycles`}>
                <img
                  src={"/assets/icons/banknote.svg"}
                  width={20}
                  alt="payroll"
                />
              </Link>
            ),
            label: "Payroll",
          },
          {
            key: "4",
            icon: (
              <Link to={`/organizations/${orgId}/expenses`}>
                <img
                  src={"/assets/icons/money.png"}
                  width={20}
                  alt="expenses"
                />
              </Link>
            ),

            label: "Expenses",
          },
          {
            key: "5",
            icon: (
              <Link to={`/organizations/${orgId}/investments`}>
                <img
                  src={"/assets/icons/charts.png"}
                  width={20}
                  alt="investments"
                />
              </Link>
            ),
            label: "Investments",
          },
          {
            key: "6",
            icon: (
              <Link to={`/organizations/${orgId}/employees`}>
                <img
                  src={"/assets/icons/groups.svg"}
                  width={20}
                  alt="employees"
                />
              </Link>
            ),
            label: "Employees",
          },
          {
            key: "7",
            icon: (
              <Link to={`/organizations/${orgId}/rewards`}>
                <StarOutlined />
              </Link>
            ),
            label: "Rewards",
          },

          {
            key: "8",
            icon: (
              <Link to={`/organizations/${orgId}/reimbursements`}>
                <img
                  src={"/assets/icons/scan/receipt.png"}
                  width={20}
                  alt="reimbursements"
                />
              </Link>
            ),
            label: "Reimbursements",
          },
          {
            key: "9",
            icon: (
              <img
                src={"/assets/icons/settings.svg"}
                width={20}
                alt="settings"
              />
            ),
            label: "Settings",
            children: [
              {
                key: "31",
                label: "Organization",
                icon: (
                  <Link to={`/organizations/${orgId}/settings`}>
                    <BankOutlined />
                  </Link>
                ),
              },
              {
                key: "32",
                label: "Tax & Fees",
                icon: (
                  <Link to={`/organizations/${orgId}/settings/taxes`}>
                    <DollarCircleOutlined />
                  </Link>
                ),
              },
            ],
          },
          {
            key: "10",
            icon: (
              <a
                href="https://hub.expensehut.com/support"
                target="_blank"
                rel="noreferrer"
              >
                <PhoneOutlined />
              </a>
            ),
            label: "Support",
          },
        ];
      } else if (profile?.type === "employee") {
        let employeeItems = [...defaultItem];

        if (profile?.settings?.orders) {
          employeeItems.push({
            key: "2.1",
            icon: (
              <Link to={`/organizations/${orgId}/sales`}>
                <img src={"/assets/icons/profits.svg"} width={20} alt="sales" />
              </Link>
            ),
            label: "Orders",
          });
        }
        if (profile?.settings?.reimbursements) {
          employeeItems.push({
            key: "2",
            icon: (
              <Link to={`/organizations/${orgId}/reimbursements`}>
                <img
                  src={"/assets/icons/scan/receipt.png"}
                  width={20}
                  alt="reimbursements"
                />
              </Link>
            ),
            label: "Reimbursements",
          });
        }

        employeeItems = [
          ...employeeItems,
          {
            key: "3",
            icon: (
              <Link to={`/organizations/${orgId}/payroll`}>
                <img
                  src={"/assets/icons/money.png"}
                  width={20}
                  alt="reimbursements"
                />
              </Link>
            ),
            label: "Payroll",
          },
          {
            key: "4",
            icon: (
              <a
                href="https://hub.expensehut.com/support"
                target="_blank"
                rel="noreferrer"
              >
                <PhoneOutlined />
              </a>
            ),
            label: "Support",
          },
        ];
        return employeeItems;
      } else {
        return [defaultItem];
      }
    }
  }, [profile, orgId, businessOwnerPhase2Items]);

  if (show) {
    return <Menu mode="inline" style={{ width: 256 }} items={items} />;
  }

  return (
    <div className="p-20">
      <MenuOutlined
        style={{ cursor: "pointer" }}
        onClick={() => setShow(true)}
      />
    </div>
  );
}
