import { Empty, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { Link, useParams } from "react-router-dom";
import { useGetExpensesAggregation } from "../../../api/api.get";

export default function ExpensesChartComponent() {
  const { id } = useParams();

  const { data: expensesData } = useGetExpensesAggregation(id);
  const [data, setData] = useState<any[][]>([]);

  useEffect(() => {
    if (expensesData) {
      const results: any[] = [["Month", "Expenses"]];
      for (const item of expensesData) {
        const date = moment(item.month_year, "YYYYMM").format("MMM YYYY");
        results.push([date, item.total]);
      }
      setData(results);
    }
  }, [expensesData]);

  /**
   *   const data = [
        ["Month", "Sales"],
        ["Oct 2024", 18000],
        ["Sept 2024", 6500],
        ["Aug 2024", 12000],
        ["July 2024", 4500],
        ["Jun 2024", 9500],
        ["May 2024", 2600],
        ["Apr 2024", 2500],
        ["Mar 2024", 1800],
        ["Feb 2024", 2800],
        ["Jan 2024", 2200],
      ];
   */

  const options = {
    chartArea: { width: "50%" },
    hAxis: {
      title: "",
      minValue: 0,
    },
    orientation: "horizontal",
    vAxis: {
      title: "Total Expenses",
    },
  };

  return (
    <>
      {expensesData && expensesData.length > 0 && (
        <Chart
          // Bar is the equivalent chart type for the material design version.
          chartType="BarChart"
          width="100%"
          height="400px"
          data={data}
          options={options}
        />
      )}
      {(!expensesData || expensesData?.length <= 0) && (
        <Empty
          description={
            <Typography.Text>
              Add <Link to={`/organizations/${id}/expenses`}>Expenses </Link>
            </Typography.Text>
          }
        />
      )}
    </>
  );
}
