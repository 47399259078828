import { Button, Popconfirm, Table, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteInvoice } from "../../../api/api.delete";
import { useGetInvoices } from "../../../api/api.get";
import { useMessage } from "../../../components/notifications/message";
import { useCurrency } from "../../../hooks/useCurrency";
import OrganizationDate from "../organization-date";

const { Title } = Typography;

export default function InvoicesHomePage() {
  const { id } = useParams();
  const [date, setDate] = useState(moment());
  const { messageError, notification } = useMessage();

  const { currency } = useCurrency();
  const navigate = useNavigate();

  const { data: expenses, refetch } = useGetInvoices(
    id,
    date.startOf("month").format("YYYY-MM-DD"),
    date.endOf("month").format("YYYY-MM-DD"),
  );

  const { mutateAsync: deleteAsync } = useDeleteInvoice(id!!);

  const handleDateChange = (dateString: string) => {
    setDate(moment(dateString, "MMM YYYY"));
  };

  useEffect(() => {
    if (date) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const handleDelete = (row: any) => {
    deleteAsync({ invoiceId: row.id })
      .then(() => refetch())
      .catch((err) => {
        messageError(
          err.response?.data?.message ?? "Whoops! Unable to delete data",
        );
      });
  };

  const columns = [
    {
      title: "ID",
      key: "externalId",
      render: (row: any) => <span>{row.externalId}</span>,
    },

    {
      title: "Amount",
      dataIndex: "total",
      key: "total",
      render: (value: string) => (
        <span>
          {currency}
          {value}
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: string) => (
        <span>{moment(value).format("DD MMM YYYY")}</span>
      ),
    },
    {
      title: "",
      key: "operationView",
      width: 100,
      render: (row: any) => (
        <Button
          type="link"
          onClick={() => navigate(`/organizations/${id}/invoices/${row.id}`)}
        >
          Edit
        </Button>
      ),
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Popconfirm
          title="Are you sure you want to delete?"
          onConfirm={() => handleDelete(row)}
        >
          <Button danger type="text" color="danger">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div
      style={{ marginBottom: 100 }}
      className="body divColumn mx-40 my-40 flex-1"
    >
      {notification}
      <div className="divCenterAlign">
        <OrganizationDate onChange={handleDateChange} />
      </div>
      <div className="divSpread divAlignItemsOnly mt-40">
        <Title level={3}>Invoices</Title>

        <Button
          type="link"
          onClick={() => navigate(`/organizations/${id}/invoices/create`)}
        >
          Create
        </Button>
      </div>
      <Table dataSource={expenses} columns={columns} />
    </div>
  );
}
