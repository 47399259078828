import { Alert, Button, Flex, Input, Modal, Tooltip } from "antd";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useGetOrganizationById } from "../../../api/api.get";
import { useUpdateOrganization } from "../../../api/api.update";
import { ROUTES } from "../../../utils/constants";

import { InfoCircleFilled } from "@ant-design/icons";
import { message } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { useDeleteOrg } from "../../../api/api.delete";
import { useNotifications } from "../../../components/notifications/notification";

export default function OrganizationTaxesSettingsPage() {
  const navigate = useNavigate();

  const { id } = useParams();
  const { mutateAsync, isPending } = useUpdateOrganization(id);

  const { mutateAsync: deleteOrgAsync, isPending: isDeletePending } =
    useDeleteOrg(id);
  const [errorMsg, setErrorMsg] = useState<string>();

  const { data: orgData } = useGetOrganizationById(id);
  const [orgDeleteConfirmText, setOrgDeleteConfirmText] = useState<string>();

  const [deletableOrgId, setDeletableOrgId] = useState<string>();
  const { notifySuccess, notification } = useNotifications({
    title: "Success",
    subtitle: "Organization successfully updated",
  });

  const handleOrgDelete = () => {
    setDeletableOrgId(id);
    deleteOrgAsync(null)
      .then(() => {
        navigate(ROUTES.HOME);
      })
      .catch(() => {
        message.error("Sorry! Something went wrong. Please try again.");
      });
  };

  const formik = useFormik({
    initialValues: {
      salesTax: orgData?.salesTax ?? 0,
      incomeTax: orgData?.incomeTax ?? 0,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      salesTax: Yup.number(),
      incomeTax: Yup.number(),
    }),
    onSubmit: async (values) => {
      setErrorMsg(undefined);
      mutateAsync({ salesTax: +values.salesTax, incomeTax: +values.incomeTax })
        .then(() => {
          notifySuccess();
        })
        .catch((err) => {
          if (err.response.data.message === "Address is invalid") {
            setErrorMsg(
              "Unable to verify address. Please double check if you have entered full address including house or unit number.",
            );
          } else {
            setErrorMsg("Whoops! Unable to update. Please try again.");
          }
        });
    },
  });

  return (
    <div id="org-tax-settings-container" className="p-40 flex-1">
      {notification}
      <div className={`divCenterAlign divColumn p-32`}>
        <Modal
          title="Delete Organization"
          open={!!deletableOrgId}
          onOk={handleOrgDelete}
          onCancel={() => setDeletableOrgId(undefined)}
          okText="Confirm"
          cancelText="Cancel"
          okButtonProps={{
            disabled: orgDeleteConfirmText !== "confirm",
            loading: isPending || isDeletePending,
          }}
        >
          <p>Are you sure?</p>
          <p>
            This is a permanent action and you will not be able to recover any
            data associated with this organizaton.
          </p>

          <p>
            <Input
              id="confirm"
              onChange={(e) => setOrgDeleteConfirmText(e.target.value)}
              placeholder="Type 'confirm'"
            />
          </p>
        </Modal>

        <Content
          className="p-20"
          style={{ minWidth: "40%", boxShadow: "3px 1px 5px 5px #e6ddd6" }}
        >
          <div className="w-100">
            <div className="mb-80">
              <div className="title mb-10">Taxes & Fees</div>
            </div>

            <div className="mt-80 mb-60">
              <form onSubmit={formik.handleSubmit}>
                <Flex vertical gap={40}>
                  <Flex vertical>
                    <h4>
                      Sales Tax{" "}
                      <Tooltip title="Used to automatically add to orders during checkout.">
                        <InfoCircleFilled
                          style={{ color: "var(--exhut-blue)" }}
                        />
                      </Tooltip>
                    </h4>
                    <div className="divColumn">
                      <Input
                        prefix={"%"}
                        {...formik.getFieldProps("salesTax")}
                      />
                    </div>
                  </Flex>

                  <Flex vertical>
                    <h4>
                      Income Tax{" "}
                      <Tooltip title="Used to subtract from net profit in home page.">
                        <InfoCircleFilled
                          style={{ color: "var(--exhut-blue)" }}
                        />
                      </Tooltip>{" "}
                    </h4>
                    <div className="divColumn">
                      <Input
                        prefix={"%"}
                        {...formik.getFieldProps("incomeTax")}
                      />
                    </div>
                  </Flex>

                  {errorMsg && (
                    <Alert
                      type="error"
                      message={errorMsg}
                      style={{ width: 300 }}
                    />
                  )}

                  <Button type="primary" onClick={formik.submitForm}>
                    Update
                  </Button>
                </Flex>
              </form>
            </div>
          </div>
        </Content>
      </div>
    </div>
  );
}
