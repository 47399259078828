import { DatePicker, Flex, Input, Modal, Select } from "antd";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreatePayment } from "../../../api/api";
import ErrorPanel from "../../../components/error.panel.component";
import { useCurrency } from "../../../hooks/useCurrency";
import { toSQL } from "../../../utils/date.utils";

export default function AddPaymentModal({
  invoiceId,
  open,
  onCancel,
}: {
  invoiceId?: string;
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const { currency, areaCode } = useCurrency();

  const { mutateAsync, isPending } = useCreatePayment(id);

  const formik = useFormik({
    initialValues: {
      paymentType: "",
      invoiceId: "",
      amount: "",
      paymentDate: "",
      notes: "",
      customerPhone: "",
    },
    validationSchema: Yup.object({
      paymentType: Yup.string().required("Please select type"),
      paymentDate: Yup.string().required("Please select date"),
      amount: Yup.string()
        .matches(/^[0-9]+$/, "Please enter valid amount")
        .required("Please enter amount"),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        ...values,
        amount: +values.amount,
        invoiceId: +invoiceId!!,
        customerPhone: `${areaCode}${formik.values.customerPhone}`,
        paymentDate: toSQL(formik.values.paymentDate),
      })
        .then(() => {
          formik.resetForm();
          onCancel();
        })
        .catch(console.error);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Add Payment"
        open={open}
        onOk={formik.submitForm}
        onCancel={() => {
          formik.resetForm();
          onCancel();
        }}
        okButtonProps={{ loading: isPending }}
      >
        <Flex gap={30} vertical className="my-40">
          <Select
            placeholder={"Select payment type"}
            onChange={(value) => formik.setFieldValue("paymentType", value)}
            style={{ width: 200 }}
            options={[
              { value: "CASH", label: "Cash" },
              { value: "CARD", label: "Card" },
              { value: "CHECK", label: "Check" },

              { value: "OTHER", label: "Other" },
            ]}
          />

          <div>
            <Input
              prefix={currency}
              placeholder="Amount"
              {...formik.getFieldProps("amount")}
            />
            <ErrorPanel message={formik.errors.amount} />
          </div>

          <div>
            <DatePicker
              onChange={(value) => formik.setFieldValue("paymentDate", value)}
              value={formik.values.paymentDate}
            />

            <ErrorPanel message={formik.errors.paymentDate} />
          </div>

          <div>
            <Input
              prefix={areaCode}
              placeholder="Customer Phone"
              {...formik.getFieldProps("customerPhone")}
            />
          </div>

          <div>
            <Input.TextArea
              placeholder="Notes"
              {...formik.getFieldProps("notes")}
            />
          </div>
        </Flex>
      </Modal>
    </form>
  );
}
