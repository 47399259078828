import { Button, Popconfirm, Table } from "antd";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDeletePayment } from "../../../api/api.delete";
import { useCurrency } from "../../../hooks/useCurrency";
import { IInvoice } from "../../../types/types";
import AddPaymentModal from "./add-payment.modal";

export default function EditInvoicePayments({
  invoiceData,
  refetchInvoiceData,
}: {
  invoiceData?: IInvoice;
  refetchInvoiceData: () => void;
}) {
  const { id } = useParams();
  const [isAddPayment, setIsAddPayment] = useState<boolean>(false);
  const { currency } = useCurrency();
  const { mutateAsync: deleteAsync } = useDeletePayment(id!!);

  const handleDelete = (row: any) => {
    deleteAsync({ paymentId: row.id })
      .then(() => {
        refetchInvoiceData();
      })
      .catch(() => {
        // notifyError();
      });
  };

  const paymentColumns = [
    {
      title: "Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (value: string) => <>{moment(value).format("DD MMM YYYY")}</>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "paymentDate",
      render: (value: string) => (
        <>
          {currency}
          {value}
        </>
      ),
    },
    {
      title: "Type",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: "Type",
      dataIndex: "customerPhone",
      key: "customerPhone",
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Popconfirm
          title="Delete"
          description="Are you sure to delete this investment?"
          onConfirm={() => handleDelete(row)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger type="text" color="danger">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className="my-60">
      <AddPaymentModal
        invoiceId={invoiceData?.id}
        open={isAddPayment}
        onCancel={() => {
          refetchInvoiceData();
          setIsAddPayment(false);
        }}
      />

      <div className="divSpread divAlignItemsOnly">
        <h3>Payments</h3>

        <Button type="link" onClick={() => setIsAddPayment(true)}>
          Create Payment
        </Button>
      </div>

      <Table dataSource={invoiceData?.payments} columns={paymentColumns} />
    </div>
  );
}
