import { Alert, Flex, Input, Modal } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateTimesheet } from "../../../api/api";
import { useGetTimeSheetsTotalForEmployee } from "../../../api/api.get";
import ErrorPanel from "../../../components/error.panel.component";
import { toSQL } from "../../../utils/date.utils";

export default function ViewTimeSheetModal({
  open,
  day,
  onCancel,
}: {
  day?: Dayjs;
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();

  const { data: totalHours, refetch } = useGetTimeSheetsTotalForEmployee(
    id,
    day?.format("YYYY-MM-DD"),
  );

  const { mutateAsync, error, isPending } = useCreateTimesheet(id);

  const formik = useFormik({
    initialValues: {
      hours: totalHours?.total ?? 0,
      timeSheetDate: day?.format("YYYY-MM-DD"),
      notes: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      timeSheetDate: Yup.string().required("Please select date"),
      hours: Yup.number()
        .typeError("Please enter valid number")
        .positive("Please enter above 0")
        .test("is-decimal", "Hours can only have once decimal", (val: any) => {
          if (val !== undefined) {
            return /^\d+(\.\d{0,1})?$/.test(val);
          }
          return true;
        })
        .min(1, "please add valid number.")
        .max(24, " You cannot enter more than 24 hours")
        .required("Please enter hours"),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        ...values,
        hours: +values.hours,
        timeSheetDate: toSQL(formik.values.timeSheetDate),
      })
        .then(() => {
          formik.resetForm();
          onCancel();
        })
        .catch(console.error);
    },
  });

  useEffect(() => {
    if (formik.values.timeSheetDate) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.timeSheetDate]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Add Hours"
        open={open}
        onOk={formik.submitForm}
        okButtonProps={{
          disabled: isPending || Object.keys(formik.errors).length > 0,
        }}
        onCancel={() => {
          formik.resetForm();
          onCancel();
        }}
      >
        <Flex gap={30} vertical>
          <div className="divCenterAlign">
            <h4>{dayjs(formik.values.timeSheetDate).format("DD MMMM YYYY")}</h4>
          </div>

          <div>
            <Input placeholder="Hours" {...formik.getFieldProps("hours")} />
            <ErrorPanel message={formik.errors.hours} />
          </div>

          <div>
            <Input placeholder="Notes" {...formik.getFieldProps("notes")} />
          </div>

          {error && (
            <div className="my-20">
              <Alert
                type="error"
                closable
                message={
                  "Whoops! Unable to add timesheet. Please contact your employer."
                }
              />
            </div>
          )}
        </Flex>
      </Modal>
    </form>
  );
}
