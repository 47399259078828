import { message } from "antd";

interface IProps {
  title?: string;
  subtitle?: string;
}

export function useMessage(props?: IProps) {
  const [messageApi, contextHolder] = message.useMessage();

  const success = (message: string) => {
    messageApi.open({
      type: "success",
      content: message,
    });
  };

  const error = (message: string) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };

  const warn = (message: string) => {
    messageApi.open({
      type: "warning",
      content: message,
    });
  };

  return {
    messageSuccess: success,
    messageWarn: warn,
    notification: contextHolder,
    messageError: error,
  };
}
