import { useEffect } from "react";
import "./App.css";
import Main from "./Main";
import "./styles/button.styles.css";
import "./styles/table.styles.css";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import LocalizationProvider from "./providers/localization.provider";

var growthbook: GrowthBook;

if (process.env.REACT_APP_GROWTHBOOK_KEY) {
  growthbook = new GrowthBook({
    apiHost: "https://cdn.growthbook.io",
    clientKey: process.env.REACT_APP_GROWTHBOOK_KEY,
    enableDevMode: true,
    subscribeToChanges: true,
    trackingCallback: (experiment, result) => {
      // TODO: Use your real analytics tracking system
      console.log("Viewed Experiment", {
        experimentId: experiment.key,
        variationId: result.key,
      });
    },
  });
}

const App = () => {
  useEffect(() => {
    if (growthbook) {
      growthbook.loadFeatures();
    }
  }, []);
  return (
    <GrowthBookProvider growthbook={growthbook}>
      <LocalizationProvider>
        <Main />
      </LocalizationProvider>
    </GrowthBookProvider>
  );
};
export default App;
