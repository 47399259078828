import { Divider, Flex, Menu } from "antd";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { Link, useLocation } from "react-router-dom";
import * as animationData from "../animations/business-home-lottie.json";
import { useGetAllOrganizations } from "../api/api.get";
import OrganizationTile from "../components/tile.component";
import { capitalize } from "../utils/functions";
import FirstTimePage from "./home/first-time.page";

export default function HomePage() {
  const { state } = useLocation();

  const { animate } = state || {};

  const { data: profiles, isLoading: isProfileLoading } =
    useGetAllOrganizations();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (animate) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  }, [animate]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={`mb-160 divFlex w-100`} id="home-body">
      <HomeLeftPaneComponent />

      {(isLoading || isProfileLoading) && (
        <div className="divCenterAlign w-100">
          <Lottie options={defaultOptions} height={400} width={400} />
        </div>
      )}

      {!isLoading && (
        <>
          {profiles && profiles.length === 0 && <FirstTimePage />}

          {profiles && profiles.length > 0 && (
            <div
              className="divSpread w-100"
              style={{
                paddingTop: 40,
                paddingRight: 40,
                paddingBottom: 40,
              }}
            >
              <div
                style={{
                  flex: 3,
                  padding: "0px 20px",
                  backgroundColor: "",
                }}
              >
                {profiles.map((p, i) => {
                  return (
                    <OrganizationTile
                      key={i}
                      title={capitalize(p.org_name)}
                      id={p.org_id.toString()}
                      admin={p.type === "admin"}
                    />
                  );
                })}
              </div>

              <div
                style={{
                  backgroundColor: "aliceblue",
                  flex: 1,
                  padding: 50,
                  borderRadius: 30,
                }}
              >
                <Flex className="divCenterAlign" gap={40} vertical>
                  <Flex gap={20} vertical>
                    <h2>Tasks</h2>
                    <strong>-</strong>
                  </Flex>
                  <Divider />

                  <Flex className="divCenterAlign" gap={20} vertical>
                    <h2>Next in line</h2>
                    <div className="divCenterAlign divColumn">
                      <img
                        src="/assets/icons/travelling.svg"
                        width={50}
                        alt="travelling"
                      />
                      <span className="my-20 infoMsg">
                        You're all caught up
                      </span>
                    </div>
                  </Flex>

                  <Divider />

                  <Flex className="divCenterAlign" gap={20} vertical>
                    <h2>Useful Tips</h2>
                    <span>
                      {profiles?.[0]?.type === "admin" && (
                        <span>
                          Click on your organization to add orders, employees,
                          rewards and much more.{" "}
                        </span>
                      )}
                      {profiles?.[0]?.type !== "admin" && (
                        <span>
                          Click on your organization to add check your salary,
                          submit timereports and reimbursements.{" "}
                        </span>
                      )}
                    </span>
                  </Flex>
                </Flex>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function HomeLeftPaneComponent() {
  const items: any = [
    {
      key: "1",
      icon: (
        <Link to={`/home`}>
          <img src={"/assets/icons/house.svg"} width={20} alt="home" />
        </Link>
      ),
      label: capitalize("home"),
    },
    {
      key: "2",
      icon: (
        <Link to={`/profile`}>
          <img src={"/assets/icons/man.svg"} width={20} alt="profile" />
        </Link>
      ),
      label: capitalize("Profile"),
    },
  ];

  return <Menu mode="inline" style={{ width: 250 }} items={items} />;
}
