import { Alert } from "antd";
import { IStatusMsg } from "../types/types";

interface IProps {
  message?: IStatusMsg;
  onClose: () => void;
}

/**
 * You must && for value.
 * @param message
 * @param onClose
 * @returns
 */
export default function StatusMessageComponent({ message, onClose }: IProps) {
  if (!message) {
    return null;
  }

  return (
    <div className="my-20" style={{ width: "auto" }}>
      <Alert message={message.text} type={message.type} />
    </div>
  );
}
