import { getCountryPhoneCode } from "./constants";

export function capitalize(input: string = "") {
  if (!input) return "";
  const words = input.split(" ");
  const result = [];
  for (const w of words) {
    result.push(`${w.charAt(0).toUpperCase()}${w.substring(1)}`);
  }
  return result.join(" ");
}

export function capitalizeWithTrunc(input: string = "") {
  const capitalized = capitalize(input);
  if (capitalized.length > 15) {
    return capitalized.substring(0, 14) + "...";
  }
  return capitalized;
}

export function getEnvNumeric(envName: string, defaultValue = 0) {
  if (process.env["envName"]) {
    return +process.env["envName"];
  }
  return defaultValue;
}

export function getCurrencySymbol(countryCode: string = "USA") {
  if (!countryCode) {
    return "$"; // always return $ in any cases
  }
  let symbol = "$";
  switch (countryCode) {
    case "IND":
      symbol = "₹";
      break;
    case "UK":
      symbol = "£";
      break;
    case "JPN":
      symbol = "¥";
      break;
  }
  return symbol;
}

export function getCurrencyCode(countryCode: string = "USA") {
  if (!countryCode) {
    return "USD"; // always return $ in any cases
  }
  let symbol = "USD";
  switch (countryCode) {
    case "IND":
      symbol = "INR";
      break;
    case "UK":
      symbol = "GBP";
      break;
    case "JPN":
      symbol = "JPY";
      break;
  }
  return symbol;
}

export function toFixed(value: number | string | undefined, decimal = 1) {
  if (!value) {
    return 0;
  }
  if (typeof value === "string") {
    return parseFloat(value).toFixed(decimal);
  }
  return value.toFixed(decimal);
}

export function matchPublicRoutes(pathname: string) {
  const PUBLIC_ROUTES = [
    "/",
    "/forgot-password",
    "/register",
    "/register/verify",
    "/login",
  ];
  return PUBLIC_ROUTES.includes(pathname);
}

export function displayPhoneNumber(
  phone?: string,
  countryCode?: string | null,
) {
  if (phone && countryCode) {
    const countryCodeToTrim = getCountryPhoneCode(countryCode);
    return phone.replace(countryCodeToTrim, "");
  }
  return "";
}

export function getRecaptchaKey() {
  return process.env.REACT_APP_RECAPTCHA_KEY ?? "";
}

export const isRecaptchaEnabled =
  process.env.REACT_APP_RECAPTCHA_ENABLED === "true";
