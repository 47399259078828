import { Flex, Modal, Radio, Select } from "antd";
import Alert from "antd/es/alert/Alert";
import Input from "antd/es/input/Input";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreatePayrollBonus } from "../../../api/api";
import { useGetOrganizationEmployees } from "../../../api/api.get";
import { useCurrency } from "../../../hooks/useCurrency";
export default function AddPayrollBonusModal({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) {
  const { id, pid: cycleId } = useParams();
  const { currency } = useCurrency();
  const {
    mutateAsync: createPayrollBonusAsync,
    error,
    isPending,
  } = useCreatePayrollBonus(id!!, cycleId!!);

  const { data: employeesData } = useGetOrganizationEmployees(id);

  const [employeeItems, setEmployeeItems] = useState<any[]>();

  const formik = useFormik({
    initialValues: {
      type: "company",
      bonusType: "flat",
      empId: "",
      amount: "",
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Please select type"),
      startDate: Yup.string().when("type", {
        is: "dates",
        then: (schema) => schema.required("Please select start date."),
      }),
      endDate: Yup.string().when("type", {
        is: "dates",
        then: (schema) => schema.required("Please select end date."),
      }),
    }),
    onSubmit: async (values) => {
      alert(JSON.stringify(values));
      createPayrollBonusAsync({
        ...values,
        empId: +formik.values.empId,
        amount: +formik.values.amount,
      })
        .then(() => {
          formik.resetForm();
          onCancel();
        })
        .catch(console.error);
    },
  });

  useEffect(() => {
    if (employeesData) {
      const items: any[] = [];
      for (const emp of employeesData) {
        items.push({ label: emp.email, value: emp.id });
      }
      setEmployeeItems(items);
    }
  }, [employeesData]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Add Bonus"
        open={open}
        onOk={formik.submitForm}
        onCancel={() => {
          formik.resetForm();
          onCancel();
        }}
        okButtonProps={{ loading: isPending }}
      >
        <Flex gap={30} vertical>
          <Radio.Group
            onChange={(e) => formik.setFieldValue("type", e.target.value)}
            value={formik.values.type}
          >
            <Radio value={"company"}>Company Wide</Radio>
            <Radio value={"employee"}>Specific Employee</Radio>
          </Radio.Group>

          {formik.values.type === "company" && (
            <div>
              <Flex gap={30} vertical>
                <Radio.Group
                  onChange={(e) =>
                    formik.setFieldValue("bonusType", e.target.value)
                  }
                  value={formik.values.bonusType}
                >
                  <Radio value={"flat"}>Flat Amount</Radio>
                  <Radio value={"percent"}>Percent</Radio>
                </Radio.Group>
              </Flex>

              <div>
                <div className="my-40">
                  {formik.values.bonusType === "flat" && (
                    <div>
                      <h4>Amount</h4>
                      <Input
                        prefix={currency}
                        placeholder="Enter amount..."
                        {...formik.getFieldProps("amount")}
                      />
                    </div>
                  )}

                  {formik.values.bonusType === "percent" && (
                    <div>
                      <h4>Percentage from every employee's base salary</h4>
                      <Input prefix={"%"} placeholder="Enter percentage..." />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {formik.values.type === "employee" && (
            <div>
              <div className="mb-40">
                <h4>Select Employee</h4>

                <Select
                  onChange={(value, row) => {
                    formik.setFieldValue("empId", value);
                  }}
                  style={{ width: 200 }}
                  options={employeeItems}
                />
              </div>

              <Flex gap={30} vertical>
                <Radio.Group
                  onChange={(e) =>
                    formik.setFieldValue("bonusType", e.target.value)
                  }
                  value={formik.values.bonusType}
                >
                  <Radio value={"flat"}>Flat Amount</Radio>
                  <Radio value={"percent"}>Percent</Radio>
                </Radio.Group>
              </Flex>

              <div>
                <div className="my-40">
                  {formik.values.bonusType === "flat" && (
                    <div>
                      <h4>Amount</h4>
                      <Input
                        prefix={currency}
                        placeholder="Enter amount..."
                        {...formik.getFieldProps("amount")}
                      />
                    </div>
                  )}

                  {formik.values.bonusType === "percent" && (
                    <div>
                      <h4>Percentage from this employee's base salary</h4>
                      <Input prefix={"%"} placeholder="Enter percentage..." />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {error && (
            <Alert
              type="error"
              message={`Whoops! ${error?.response?.data?.message ?? "Unable to create cycle."} `}
            />
          )}
        </Flex>
      </Modal>
    </form>
  );
}
