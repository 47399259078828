import { PropsWithChildren } from "react";
import { useParams } from "react-router-dom";
import { useGetProfileOnOrganization } from "../api/api.get";
import NoAccessPage from "../pages/no-access.page";

export function RequireSalesSettingsOrAdmin(props: PropsWithChildren) {
  const { id: orgId } = useParams();

  const { data: profile } = useGetProfileOnOrganization(orgId);

  if (
    (profile?.type === "employee" && profile?.settings?.orders === true) ||
    profile?.type === "admin"
  ) {
    return <> {props.children} </>;
  }
  return <NoAccessPage />;
}

export function RequireReimbursementsSettingsOrAdmin(props: PropsWithChildren) {
  const { id: orgId } = useParams();
  const { data: profile } = useGetProfileOnOrganization(orgId);

  if (
    (profile?.type === "employee" &&
      profile?.settings?.reimbursements === true) ||
    profile?.type === "admin"
  ) {
    return <> {props.children} </>;
  }
  return <NoAccessPage />;
}
