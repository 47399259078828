import { Alert, Flex, Input, Modal, Radio } from "antd";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useAddDeductionToEmployee } from "../../../api/api";
import ErrorPanel from "../../../components/error.panel.component";
import { useCurrency } from "../../../hooks/useCurrency";

export default function AddDeductionModal({
  open,
  empId,
  onCancel,
}: {
  open: boolean;
  empId?: string;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const { currency } = useCurrency();

  const { mutateAsync, error, isPending } = useAddDeductionToEmployee(
    id,
    empId,
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      value: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter a name"),
      type: Yup.string().required("Please select type"),
      value: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter numbers only")
        .required("Please enter value"),
    }),
    onSubmit: async (values) => {
      mutateAsync({ ...values, value: +values.value })
        .then(() => {
          formik.resetForm();
          onCancel();
        })
        .catch(console.error);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Add Deduction"
        open={open}
        onOk={formik.submitForm}
        okButtonProps={{ loading: isPending }}
        okText="Add"
        onCancel={() => {
          formik.resetForm();
          onCancel();
        }}
      >
        <Flex gap={30} vertical style={{ padding: 20 }}>
          <div>
            <Input placeholder="name" {...formik.getFieldProps("name")} />
            <ErrorPanel message={formik.errors.name} />
          </div>

          <div>
            {/**
             * Don't make this as select. Cypress test can't recognize the items and its a nightmare.
             */}
            <Radio.Group
              onChange={(e) => formik.setFieldValue("type", e.target.value)}
              value={formik.values.type}
            >
              <Radio value={"flat"}>Flat</Radio>
              <Radio value={"percent"}>Percent</Radio>
            </Radio.Group>
          </div>

          <div>
            <Input
              prefix={formik.values.type === "percent" ? "%" : currency}
              placeholder="Enter value..."
              {...formik.getFieldProps("value")}
            />
            <ErrorPanel message={formik.errors.value} />
          </div>

          {error && (
            <Alert
              message={
                error.response.data?.message ??
                "Whoops! Something went wrong. Please try again."
              }
              type="error"
            />
          )}
        </Flex>
      </Modal>
    </form>
  );
}
