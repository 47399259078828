import { Button, Popconfirm, Table, Typography } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDeletePayrollBonus } from "../../../../api/api.delete";
import {
  useGetOrganizationEmployees,
  useGetPayrollBonuses,
} from "../../../../api/api.get";
import { useNotifications } from "../../../../components/notifications/notification";
import { useCurrency } from "../../../../hooks/useCurrency";
import { capitalize } from "../../../../utils/functions";
import AddPayrollBonusModal from "../add-payroll-bonus.modal";

export default function PayrollBonusesSection() {
  const { id, pid: cycleId } = useParams();
  const { notifyError } = useNotifications();
  const { currency } = useCurrency();

  const { data: bonusesData } = useGetPayrollBonuses(id, cycleId);
  const { data: employees } = useGetOrganizationEmployees(id);

  const { mutateAsync: deletePayrollBonusAsync } = useDeletePayrollBonus(
    id!!,
    cycleId!!,
  );

  const [isAddBonus, setIsAddBonus] = useState<boolean>(false);

  const handleDeletePayrollBonus = (id: string) => {
    deletePayrollBonusAsync({ payrollBonusId: id }).catch(() => {
      notifyError();
    });
  };

  const bonusesColumns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (value: string) => <>{capitalize(value)}</>,
    },
    {
      title: "Bonus Type",
      dataIndex: "bonusType",
      key: "bonusType",
      render: (value: string) => <>{capitalize(value)}</>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (value: string) => (
        <>
          {currency ?? ""}
          {value}
        </>
      ),
    },
    {
      title: "Employee",
      dataIndex: "empId",
      key: "empId",
      render: (value: number) =>
        employees?.find((emp) => +emp.id === value)?.email,
    },

    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <div>
          {row.id && (
            <Popconfirm
              title="Delete"
              description="Are you sure you want to delete?"
              onConfirm={() => handleDeletePayrollBonus(row.id)}
            >
              <Button danger type="text" color="danger">
                Delete
              </Button>
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];

  return (
    <div style={{ marginBottom: 100 }} className="body divColumn p-0 flex-1">
      <AddPayrollBonusModal
        open={isAddBonus}
        onCancel={() => setIsAddBonus(false)}
      />

      <div className="card my-40">
        <div className="divSpread divAlignItemsOnly">
          <Typography.Title level={5}>Bonuses</Typography.Title>

          <Button type="primary" onClick={() => setIsAddBonus(true)}>
            Add Bonus
          </Button>
        </div>

        <div className="my-20">
          <span className="errorMsg mt-20">
            * Bonuses will be applied to this specific cycle.
          </span>
        </div>

        <Table bordered dataSource={bonusesData} columns={bonusesColumns} />
      </div>
    </div>
  );
}
