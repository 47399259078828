import { Checkbox, DatePicker, Flex, Input, Modal, Select } from "antd";
import { useFormik } from "formik";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateOrgSale } from "../../../api/api";
import { useGetOrganizationById } from "../../../api/api.get";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { useCurrency } from "../../../hooks/useCurrency";
import { toSQL } from "../../../utils/date.utils";

export default function AddSalesModal({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const { currency, areaCode } = useCurrency();
  const { data: orgData } = useGetOrganizationById(id);

  const { notifySuccess, notification, notifyError } = useNotifications({
    title: "Success",
    subtitle: "Sale created successfully.",
  });

  const { mutateAsync, isPending } = useCreateOrgSale(id);

  const formik = useFormik({
    initialValues: {
      category: "",
      amount: "",
      saleDate: "",
      notes: "",
      phone: "",
      paymentType: "",
      total: 0,
      salesTaxIncluded: true,
      tax: orgData?.salesTax ?? 0,
      showPayment: false,
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Please enter a description."),
      saleDate: Yup.string().required("Please select date"),
      amount: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid amount")
        .required("Please enter amount"),
      total: Yup.number(),
      salesTaxIncluded: Yup.bool(),
      showPayment: Yup.bool(),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        ...values,
        amount: +values.amount,
        phone: areaCode + values.phone,
        saleDate: toSQL(formik.values.saleDate),
      })
        .then(() => {
          formik.resetForm();
          notifySuccess();
          onCancel();
        })
        .catch(() => {
          notifyError();
        });
    },
  });

  const total: number = useMemo(() => {
    const _amount = +(formik.values.amount ?? 0);
    if (formik.values.salesTaxIncluded) {
      const salesTax = ((orgData?.salesTax ?? 0) / 100) * _amount;
      return _amount + salesTax;
    }
    return _amount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.salesTaxIncluded, formik.values.amount]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {notification}
      <Modal
        title="Add Order"
        open={open}
        onOk={formik.submitForm}
        onCancel={() => {
          formik.resetForm();
          onCancel();
        }}
        okButtonProps={{ loading: isPending }}
        width={800}
      >
        <Flex gap={100} style={{ width: "100%" }}>
          <Flex gap={20} vertical style={{ padding: 20, flex: 1 }}>
            <div>
              <Input
                placeholder="Description - For ex: Sold 2 desks.."
                {...formik.getFieldProps("category")}
              />
              <ErrorPanel message={formik.errors.category} />
            </div>

            <div>
              <Input
                prefix={currency}
                placeholder="Amount"
                {...formik.getFieldProps("amount")}
              />
              <ErrorPanel message={formik.errors.amount} />
            </div>

            <div>
              <DatePicker
                value={formik.values.saleDate}
                onChange={(value) => formik.setFieldValue("saleDate", value)}
              />

              <ErrorPanel message={formik.errors.saleDate} />
            </div>

            <div>
              <Input.TextArea
                placeholder="Notes"
                {...formik.getFieldProps("notes")}
              />
            </div>

            <Checkbox
              checked={formik.values.showPayment}
              onChange={() =>
                formik.setFieldValue("showPayment", !formik.values.showPayment)
              }
            >
              Add Payment
            </Checkbox>

            <Checkbox
              checked={formik.values.salesTaxIncluded}
              onChange={(e) =>
                formik.setFieldValue("salesTaxIncluded", e.target.checked)
              }
            >
              Sales Tax {" - " + (orgData?.salesTax ?? 0) + "%"}
            </Checkbox>

            <h2 id="total">
              Total: {currency}
              {total.toFixed(2)}
            </h2>
          </Flex>
          {formik.values.showPayment && (
            <Flex gap={20} vertical style={{ padding: 20, flex: 1 }}>
              <div>
                <Select
                  placeholder={"Select payment type"}
                  onChange={(value) =>
                    formik.setFieldValue("paymentType", value)
                  }
                  style={{ width: 200 }}
                  options={[
                    { value: "CASH", label: "Cash" },
                    { value: "CARD", label: "Card" },
                    { value: "CHECK", label: "Check" },

                    { value: "OTHER", label: "Other" },
                  ]}
                />
              </div>

              <div>
                <Input
                  prefix={areaCode}
                  placeholder="Customer Phone"
                  {...formik.getFieldProps("phone")}
                />
              </div>
            </Flex>
          )}
        </Flex>
      </Modal>
    </form>
  );
}
