import { DatePicker, Flex, Input, Modal } from "antd";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateOrgInvestment } from "../../../api/api";
import ErrorPanel from "../../../components/error.panel.component";
import { useCurrency } from "../../../hooks/useCurrency";
import { toSQL } from "../../../utils/date.utils";

export default function AddInvestmentModal({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const { currency } = useCurrency();

  const { mutateAsync, isPending } = useCreateOrgInvestment(id);

  const formik = useFormik({
    initialValues: {
      category: "",
      amount: "",
      investmentDate: "",
      notes: "",
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Please enter name"),
      investmentDate: Yup.string().required("Please select date"),
      amount: Yup.string()
        .matches(/^[0-9]+$/, "Please enter valid amount")
        .required("Please enter amount"),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        ...values,
        amount: +values.amount,
        investmentDate: toSQL(formik.values.investmentDate),
      })
        .then(() => {
          formik.resetForm();
          onCancel();
        })
        .catch(console.error);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Add Investment"
        open={open}
        onOk={formik.submitForm}
        onCancel={() => {
          formik.resetForm();
          onCancel();
        }}
        okButtonProps={{ loading: isPending }}
      >
        <Flex gap={30} vertical>
          <div>
            <Input
              placeholder="Description"
              {...formik.getFieldProps("category")}
            />
            <ErrorPanel message={formik.errors.category} />
          </div>

          <div>
            <Input
              prefix={currency}
              placeholder="Amount"
              {...formik.getFieldProps("amount")}
            />
            <ErrorPanel message={formik.errors.amount} />
          </div>

          <div>
            <DatePicker
              value={formik.values.investmentDate}
              onChange={(value) =>
                formik.setFieldValue("investmentDate", value)
              }
            />

            <ErrorPanel message={formik.errors.investmentDate} />
          </div>

          <div>
            <Input placeholder="Notes" {...formik.getFieldProps("notes")} />
          </div>
        </Flex>
      </Modal>
    </form>
  );
}
