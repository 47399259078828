import { IUser } from "../../../types/types";

import { MenuOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Menu } from "antd";

type MenuItem = Required<MenuProps>["items"][number];

export default function LeftPaneItems({
  onPostClick,
  anchorEl,
  open,
  user,
}: {
  open: boolean;
  anchorEl: HTMLElement | null;
  onPostClick: (key: string) => void;
  user?: IUser | null;
}) {
  const items: MenuItem[] = !!user
    ? [
        {
          key: "SubMenu",
          icon: <MenuOutlined style={{ color: "white" }} />,
          children: [{ label: "Log out", key: "logout" }],
        },
      ]
    : [
        {
          key: "SubMenu",
          icon: <MenuOutlined style={{ color: "white" }} />,
          children: [
            { label: "Login", key: "login" },
            { label: "What's ExpenseHut", key: "whatis" },
          ],
        },
      ];

  const onClick: MenuProps["onClick"] = (e) => {
    onPostClick(e.key);
  };

  return (
    <Menu
      id="options-menu"
      onClick={onClick}
      mode="horizontal"
      style={{ backgroundColor: "inherit" }}
      items={items}
    />
  );
}
