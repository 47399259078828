export default function ErrorPanel({ message }: { message?: string }) {
  return (
    <div style={{ minHeight: 20, marginTop: 10 }}>
      {message && (
        <span className="errorMsg" style={{ fontSize: "small" }}>
          {message}
        </span>
      )}
    </div>
  );
}
