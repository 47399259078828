import { Button, Empty, Table } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetPayrolls } from "../../../api/api.get";
import { IPayroll } from "../../../types/types";
import ViewPayStubModal from "./view-paystub.modal";

export default function PayrollHomEmployeePage() {
  const { id } = useParams();

  const [payrollId, setPayrollId] = useState<number>();

  const { data: payrolls, isLoading } = useGetPayrolls(id);

  const cycleColumns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Statement Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: string) => (
        <div>{dayjs(value).format("DD MMM YYYY")}</div>
      ),
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: IPayroll) => (
        <div>
          {row.id && (
            <Button
              type="link"
              color="danger"
              onClick={() => setPayrollId(+row.id)}
            >
              View
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div style={{ marginBottom: 100 }} className="body divColumn p-40 flex-1">
      <ViewPayStubModal
        open={!!payrollId}
        onCancel={() => setPayrollId(undefined)}
        payrollId={payrollId!}
      />
      <div className="title">Payroll</div>

      <div>
        {payrolls && (
          <>
            {payrolls.length > 0 && (
              <Table
                loading={isLoading}
                dataSource={payrolls}
                columns={cycleColumns}
              />
            )}
            {payrolls.length === 0 && (
              <Empty description="No pay statements generated yet." />
            )}
          </>
        )}
      </div>
    </div>
  );
}
