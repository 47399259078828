import {
  Alert,
  Button,
  Descriptions,
  message,
  Popconfirm,
  Table,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeletePayrollCycle,
  useDeletePayrollSchedule,
} from "../../../api/api.delete";
import {
  useGetPayrollCycles,
  useGetPayrollSchedule,
} from "../../../api/api.get";
import { useNotifications } from "../../../components/notifications/notification";
import { IPayroll } from "../../../types/types";
import { capitalize } from "../../../utils/functions";
import AddPayrollCycleModal from "./add-payroll-cycle.modal";
import AddPayrollScheduleModal from "./add-payroll-schedule-modal";

const { Title } = Typography;

export default function PayrollHomePage() {
  const { id } = useParams();
  const [date] = useState(moment());
  const { notifyError } = useNotifications();

  const navigate = useNavigate();

  const { data: schedule, refetch } = useGetPayrollSchedule(id);

  const { data: cycles } = useGetPayrollCycles(id);

  const { mutateAsync: deleteScheduleAsync } = useDeletePayrollSchedule(id!!);

  const { mutateAsync: deletePayrollCycleAsync } = useDeletePayrollCycle(id!!);
  const [isAddCycle, setIsAddCycle] = useState<boolean>(false);

  const [isAdd, setIsAdd] = useState<boolean>(false);

  useEffect(() => {
    if (date) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const handleDeleteSchedule = (id?: string) => {
    deleteScheduleAsync({ scheduleId: id })
      .then(() => {
        message.success("Schedule successfully deleted.");
        refetch();
      })
      .catch(notifyError);
  };

  const handleDeletePayrollCycle = (id: string) => {
    deletePayrollCycleAsync({ cycleId: id }).catch(() => {
      notifyError();
    });
  };

  const cycleColumns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: IPayroll) => (
        <div>
          {row.id && (
            <Button
              type="link"
              color="danger"
              onClick={() =>
                navigate(`/organizations/${id}/payroll-cycles/${row.id}`)
              }
            >
              View
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <div>
          {row.id && (
            <Popconfirm
              title="Delete"
              description="Are you sure you want to delete?"
              onConfirm={() => handleDeletePayrollCycle(row.id)}
            >
              <Button danger type="text" color="danger">
                Delete
              </Button>
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];

  return (
    <div style={{ marginBottom: 100 }} className="body divColumn p-40 flex-1">
      <AddPayrollScheduleModal open={isAdd} onCancel={() => setIsAdd(false)} />

      <AddPayrollCycleModal
        open={isAddCycle}
        onCancel={() => setIsAddCycle(false)}
      />

      <div className="title">Payroll</div>

      {schedule && (
        <div>
          <div className="divRight">
            <Popconfirm
              title="Delete"
              description="Are you sure to delete this schedule? Previously created payroll entries will not be impacted."
              onConfirm={() => handleDeleteSchedule(schedule?.id)}
            >
              <Button danger type="text" color="danger">
                Delete Schedule
              </Button>
            </Popconfirm>
          </div>

          <Descriptions
            bordered
            items={[
              {
                key: "1",
                label: "Schedule",
                children: <div>{capitalize(schedule?.schedule)}</div>,
              },
              {
                key: "2",
                label: "Start Date",
                children: schedule?.startDate,
              },
            ]}
          />
        </div>
      )}
      {!schedule && (
        <div className="divCenterAlign">
          <Alert message="Please add a schedule." type="warning" />
        </div>
      )}

      <div className="divRight my-40">
        <div className="divRight mb-20">
          {!schedule && (
            <Button type="primary" onClick={() => setIsAdd(!isAdd)}>
              Add Schedule
            </Button>
          )}
        </div>
      </div>

      <div className="card my-0">
        <div className="divSpread divAlignItemsOnly">
          <Title level={5}>Payroll Cycles</Title>
          <Tooltip title="Please add schedule">
            <Button
              disabled={!schedule}
              type="link"
              onClick={() => setIsAddCycle(!isAddCycle)}
            >
              Create Cycle
            </Button>
          </Tooltip>
        </div>

        {cycles && cycles.length > 0 && (
          <Table dataSource={cycles} columns={cycleColumns} />
        )}
      </div>
    </div>
  );
}
