import { PropsWithChildren } from "react";
import { useParams } from "react-router-dom";
import { useGetProfileOnOrganization } from "../api/api.get";
import NoAccessPage from "../pages/no-access.page";

export function RequireEmployeeAccess(props: PropsWithChildren) {
  const { id: orgId } = useParams();

  const { data: profile } = useGetProfileOnOrganization(orgId);

  if (profile?.type === "employee") {
    return <> {props.children} </>;
  }

  return <NoAccessPage />;
}

export function RequireOrgAdminAccess(props: PropsWithChildren) {
  const { id: orgId } = useParams();

  const { data: profile } = useGetProfileOnOrganization(orgId);

  if (profile?.type === "admin") {
    return <> {props.children} </>;
  }

  return <NoAccessPage />;
}
