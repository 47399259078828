import { useFeatureIsOn } from "@growthbook/growthbook-react";
import {
  Button,
  Checkbox,
  DatePicker,
  Descriptions,
  Divider,
  Flex,
  Input,
  Popconfirm,
  Skeleton,
  Switch,
  Table,
} from "antd";
import dayjs from "dayjs";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useDeleteDeduction } from "../../../api/api.delete";
import {
  useGetEmployeeDeductions,
  useGetOrganizationEmployeesById,
} from "../../../api/api.get";
import { useUpdateEmployee } from "../../../api/api.update";
import ErrorPanel from "../../../components/error.panel.component";
import { useMessage } from "../../../components/notifications/message";
import { useNotifications } from "../../../components/notifications/notification";
import { useCurrency } from "../../../hooks/useCurrency";
import { IDeduction } from "../../../types/types";
import { toSQL } from "../../../utils/date.utils";
import { capitalize } from "../../../utils/functions";
import AddDeductionModal from "./add-deduction.modal";

export default function ViewEmployeePage() {
  const { id: orgId, eid: empId } = useParams();

  const { currency } = useCurrency();

  const { notification, notifySuccess, notifyError } = useNotifications();

  const { data: employeeData, isLoading: isEmpDetailsLoading } =
    useGetOrganizationEmployeesById(orgId, empId);
  const { mutateAsync } = useUpdateEmployee(orgId, empId);

  const { data: deductionsData, refetch } = useGetEmployeeDeductions(
    orgId,
    empId,
  );

  const [addDeductionMode, setAddDeductionMode] = useState<boolean>(false);

  const { mutateAsync: deleteDeductionAsync } = useDeleteDeduction(
    orgId,
    empId,
  );

  const [items, setItems] = useState<any[]>([]);

  const handleDeleteDeduction = (row: IDeduction) => {
    deleteDeductionAsync({ deductionId: row.id })
      .then(() => {
        refetch();
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (employeeData) {
      const _items: any[] = [];
      _items.push({
        key: "1",
        label: "Role",
        children: employeeData.type,
      });
      _items.push({
        key: "2",
        label: "Email",
        children: employeeData.email,
      });
      _items.push({
        key: "3",
        label: "Contractor/Salaried",
        children: employeeData.employmentType,
      });
      _items.push({
        key: "4",
        label: "Start Date",
        children: employeeData.employmentStartDate
          ? moment(employeeData.employmentStartDate).format("DD MMM YYYY")
          : "",
      });
      _items.push({
        key: "5",
        label: "Employment End Date",
        children: employeeData.employmentEndDate ? (
          <span className="errorMsg">
            {moment(employeeData.employmentEndDate).format("DD MMM YYYY")}
          </span>
        ) : (
          ""
        ),
      });
      setItems(_items);
    }
  }, [employeeData]);

  const formik = useFormik({
    initialValues: {
      salary: employeeData?.salary ?? 0,
      employmentEndDate: "",
      endEmployment: false,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      salary: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid salary")
        .required("Please enter salary"),
      endEmployment: Yup.boolean(),
      employmentEndDate: Yup.string().when("endEmployment", {
        is: true,
        then: (schema) => schema.required("Please enter end date"),
      }),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        ...values,
        employmentEndDate: toSQL(formik.values.employmentEndDate),
      })
        .then(() => {
          formik.resetForm();
          notifySuccess();
        })
        .catch(() => {
          notifyError();
        });
    },
  });

  const deductionsColumns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      render: (value: string) => <span>{capitalize(value)}</span>,
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "type",
    },
    {
      title: "Value",
      key: "value",
      dataIndex: "value",
      width: 100,
    },
    {
      title: "Delete",
      key: "operation",
      render: (row: IDeduction) => (
        <Popconfirm
          onConfirm={() => handleDeleteDeduction(row)}
          title={"Are you sure you want to delete?"}
        >
          <Button type="link" danger>
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className="p-40">
      {notification}
      <AddDeductionModal
        open={addDeductionMode}
        empId={empId}
        onCancel={() => setAddDeductionMode(false)}
      />
      <div className="divSpread my-20">
        <Link to={`/organizations/${orgId}/employees`}>Back</Link>
        <Button type="primary" onClick={formik.submitForm}>
          Save
        </Button>
      </div>

      <div className="my-40">
        <form onSubmit={formik.handleSubmit}>
          <Flex gap={30} vertical>
            {isEmpDetailsLoading && <Skeleton />}
            {!isEmpDetailsLoading && (
              <Descriptions title="Employee Details" bordered items={items} />
            )}
            <Divider />

            <div className="card">
              <Flex vertical gap={20}>
                <strong>
                  {employeeData?.employmentType === "salaried"
                    ? "Annual Salary"
                    : "Hourly Salary"}
                </strong>
                <Input
                  prefix={currency}
                  disabled={!!employeeData?.employmentEndDate}
                  placeholder="salary"
                  {...formik.getFieldProps("salary")}
                />
                <ErrorPanel message={formik.errors.salary} />
              </Flex>

              <Flex vertical gap={20}>
                {!employeeData?.employmentEndDate && (
                  <Checkbox
                    onChange={(e) =>
                      formik.setFieldValue("endEmployment", e.target.checked)
                    }
                  >
                    <span className="errorMsg">End Employment</span>
                  </Checkbox>
                )}

                {formik.values.endEmployment && (
                  <div className="mx-20">
                    <DatePicker
                      disabledDate={(current) =>
                        current &&
                        current <
                          dayjs(employeeData?.employmentStartDate).endOf("day")
                      }
                      onChange={(value) =>
                        formik.setFieldValue("employmentEndDate", value)
                      }
                      value={formik.values.employmentEndDate}
                    />

                    <ErrorPanel message={formik.errors.employmentEndDate} />
                  </div>
                )}
              </Flex>
            </div>
          </Flex>
        </form>
        <Divider />

        <div className="card">
          <div className="divSpread">
            <h3>Deductions</h3>
            <Button
              type="link"
              disabled={!!employeeData?.employmentEndDate}
              onClick={() => setAddDeductionMode(!addDeductionMode)}
            >
              Add Deduction
            </Button>
          </div>

          <Table dataSource={deductionsData} columns={deductionsColumns} />
        </div>

        <EmployeeSettings />
      </div>
    </div>
  );
}

function EmployeeSettings() {
  const { id: orgId, eid: empId } = useParams();
  const isPhase3Enabled = useFeatureIsOn("enable-business-phase3");
  const { mutateAsync } = useUpdateEmployee(orgId, empId);
  const { data: employeeData } = useGetOrganizationEmployeesById(orgId, empId);
  const [orders, setOrders] = useState<boolean>();
  const [reimbursements, setReimbursements] = useState<boolean>();
  const { notification, messageSuccess, messageError } = useMessage();

  useEffect(() => {
    if (employeeData) {
      setOrders(employeeData?.settings?.orders);
      setReimbursements(employeeData?.settings?.reimbursements);
    }
  }, [employeeData]);

  const onSettingsChanged = (params: any) => {
    mutateAsync({
      settings: {
        ...employeeData?.settings,
        ...params,
      },
    })
      .then(() => {
        messageSuccess("Settings successfully changed");
      })
      .catch(() => {
        messageError("Whoops! Something went wrong. Please try again.");
      });
  };

  return (
    <div>
      {notification}
      {isPhase3Enabled && (
        <div className="my-20 card">
          <h2>Settings</h2>
          <div className="my-40">
            <Flex vertical gap={20}>
              <div className="divSpread">
                <strong>Show Orders</strong>
                <Switch
                  id="enable-orders"
                  checked={orders}
                  onChange={(checked) => onSettingsChanged({ orders: checked })}
                />
              </div>
              <div className="divSpread">
                <strong>Show Reimbursements</strong>
                <Switch
                  id="enable-reimbursements"
                  checked={reimbursements}
                  onChange={(checked) =>
                    onSettingsChanged({ reimbursements: checked })
                  }
                />
              </div>
            </Flex>
          </div>
        </div>
      )}
    </div>
  );
}
