import {
  Alert,
  Button,
  message,
  Popconfirm,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  useCreatePayroll,
  useCreatePayrollForEmployee,
} from "../../../../api/api";
import { useDeletePayroll } from "../../../../api/api.delete";
import {
  useGetOrganizationEmployees,
  useGetPayrollSchedule,
  useGetPayrollsForAdmin,
} from "../../../../api/api.get";
import { useNotifications } from "../../../../components/notifications/notification";
import { IPayroll } from "../../../../types/types";
import ViewPayStubModal from "../view-paystub.modal";

export default function PayrollDetailsSection() {
  const { id, pid: cycleId } = useParams();
  const { notifyError } = useNotifications();
  const [viewablePayrollId, setViewablePayrollId] = useState<number>();

  const { data: schedule, refetch } = useGetPayrollSchedule(id);

  const { data: payrollData } = useGetPayrollsForAdmin(id, cycleId);

  const { data: employees } = useGetOrganizationEmployees(id);

  const { mutateAsync: createPayrollAsync, isPending: generatePending } =
    useCreatePayroll(id!!);

  const {
    mutateAsync: generatePayrollForEmployee,
    isPending: generateForEmployeePending,
  } = useCreatePayrollForEmployee(id, cycleId);

  const { mutateAsync: deletePayrollAsync } = useDeletePayroll(id!!, cycleId!!);

  const handleRunPayroll = () => {
    createPayrollAsync({ cycleId: +cycleId! })
      .then(() => {
        message.success("Payroll successfully completed.");
        refetch();
      })
      .catch(notifyError);
  };

  const handleRunPayrollForEmployee = (empId: number) => {
    generatePayrollForEmployee({ empId: +empId!, cycleId: +cycleId! })
      .then(() => {
        message.success("Payroll successfully completed.");
        refetch();
      })
      .catch(() => {
        message.error(
          "Error running payroll. Please make sure employee has their profile details up to date.",
        );
      });
  };

  const handleDeletePayrollBonus = (id: string) => {
    deletePayrollAsync({ payrollId: id }).catch(() => {
      notifyError();
    });
  };

  const columns = [
    {
      title: "Employee Id",
      dataIndex: "employeeId",
      key: "employeeId",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Salary",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: IPayroll) => (
        <div>
          {row.amount !== undefined && (
            <Button type="link" onClick={() => setViewablePayrollId(+row.id)}>
              View
            </Button>
          )}
          {row.amount === undefined && (
            <Button
              type="link"
              onClick={() => handleRunPayrollForEmployee(row.employeeId)}
              loading={generateForEmployeePending}
            >
              Run
            </Button>
          )}
        </div>
      ),
    },

    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <div>
          {row.amount !== undefined && (
            <Popconfirm
              title="Delete"
              description="Are you sure you want to delete?"
              onConfirm={() => handleDeletePayrollBonus(row.id)}
            >
              <Button danger type="text" color="danger">
                Delete
              </Button>
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <ViewPayStubModal
        payrollId={viewablePayrollId}
        open={!!viewablePayrollId}
        onCancel={() => setViewablePayrollId(undefined)}
      />

      <div className="card my-40">
        {schedule && (
          <div className="divSpread mt-40">
            <Tooltip title="Add employees to run payroll">
              <Button
                disabled={employees?.length === 1 || generatePending}
                type="primary"
                onClick={handleRunPayroll}
              >
                Run Payroll For All
              </Button>
            </Tooltip>
          </div>
        )}

        {!schedule && (
          <Alert
            type="error"
            message={"You do not have a payroll schedule to run payroll"}
          />
        )}

        <Typography.Title level={5}>Payroll Details</Typography.Title>
        <Table bordered dataSource={payrollData} columns={columns} />
      </div>
    </>
  );
}
