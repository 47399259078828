import { Link } from "react-router-dom";
import { useGetOrganizationLogoById } from "../api/api.get";

interface ITileProps {
  title: string;
  id: string;
  admin: boolean;
}

export default function OrganizationTile({ title, id, admin }: ITileProps) {
  const { data: logoData } = useGetOrganizationLogoById(id);

  return (
    <div
      className="card orgtile"
      id={id}
      style={{
        padding: 40,
        marginBottom: 40,
      }}
    >
      <div className="divColumn" style={{ height: "100%" }}>
        <div className="divCenterAlign divColumn">
          <img
            alt="cityscape"
            src={logoData?.logo ?? "/assets/icons/cityscape.png"}
            width={"15%"}
            style={{ borderRadius: 10 }}
          />
          <p style={{ fontSize: "xx-large", fontWeight: "bold" }}> {title}</p>
        </div>
        <div className="divCenterAlign">
          {!admin && <span className="infoMsg">EMPLOYEE</span>}
          {admin && <span className="infoMsg">OWNER</span>}
        </div>

        <div className="my-20 divCenterAlign">
          <Link to={`/organizations/${id}`}>View</Link>
        </div>
      </div>
    </div>
  );
}
