import { Divider, Flex, Input, List, Modal } from "antd";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  useGetOrganizationById,
  useGetOrganizationSalesById,
  useSearchProductsByMultipleIds,
} from "../../../api/api.get";
import { useUpdateSale } from "../../../api/api.update";
import { useNotifications } from "../../../components/notifications/notification";
import { useCurrency } from "../../../hooks/useCurrency";
import { IOrganizationSale, IProduct } from "../../../types/types";

export default function ViewSalesModal({
  open,
  onCancel,
  sales,
}: {
  sales?: IOrganizationSale;
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();

  const { currency, areaCode } = useCurrency();

  const { notifySuccess, notification, notifyError } = useNotifications({
    title: "Success",
    subtitle: "Sale created successfully.",
  });
  const { data: saleData } = useGetOrganizationSalesById(id, sales?.id);

  const { data: searchByMulitpleProductsData } = useSearchProductsByMultipleIds(
    id,
    saleData?.products,
  );
  const [items, setItems] = useState<IProduct[]>([]);

  useEffect(() => {
    if (searchByMulitpleProductsData && saleData?.products) {
      const _items = [];
      // Invoice can have multiple same product ids but search api will return only one
      // So query for all product ids, then fetch product data.
      for (const id of saleData?.products) {
        const productData = searchByMulitpleProductsData.find(
          (p: IProduct) => p._id === id,
        );
        _items.push({
          _id: productData?._id ?? "",
          title: productData?.title ?? "",
          price: productData?.price,
          createdAt: productData?.createdAt ?? "",
        });
      }
      setItems(_items);
    }
  }, [searchByMulitpleProductsData, saleData?.products]);

  const { mutateAsync, isPending } = useUpdateSale(id, sales?.id);
  const { data: orgData } = useGetOrganizationById(id);

  const formik = useFormik({
    initialValues: {
      category: sales?.category ?? "",
      amount: sales?.amount ?? 0,
      saleDate: sales?.saleDate ?? "",
      notes: sales?.notes ?? "",
      phone: sales?.payments?.[0].customerPhone ?? "",
      paymentType: sales?.payments?.[0].paymentType ?? "",
      tax: orgData?.salesTax ?? 0,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      category: Yup.string().required("Please enter name"),
      saleDate: Yup.string().required("Please select date"),
      amount: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid salary")
        .required("Please enter amount"),
      paymentType: Yup.string(),
      tax: Yup.string().matches(
        /^(0|[1-9]\d*)(\.\d+)?$/,
        "Please enter valid salary",
      ),
    }),
    onSubmit: async (values) => {
      mutateAsync({ ...values, amount: +total })
        .then(() => {
          formik.resetForm();
          notifySuccess();
          onCancel();
        })
        .catch(() => notifyError());
    },
  });

  const total: number = useMemo(() => {
    const _amount = +(formik.values.amount ?? 0);
    if (formik.values.tax) {
      const salesTax = (formik.values.tax / 100) * _amount;
      return _amount + salesTax;
    }
    return _amount;
  }, [formik.values.tax, formik.values.amount]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {notification}
      <Modal
        title="Edit Order"
        open={open}
        onOk={formik.submitForm}
        onCancel={onCancel}
        okText="Save"
        okButtonProps={{ loading: isPending }}
      >
        <Flex>
          <Flex gap={20} vertical style={{ padding: 20, flex: 1 }}>
            <div>
              <h4>Name</h4>
              <span>{saleData?.category}</span>
            </div>

            <div>
              <h4>Amount</h4>
              <span>
                {currency}
                {saleData?.amount}
              </span>
            </div>

            <div className="divColumn">
              <h4>Sale Date</h4>
              <div className="divFlex divAlignItemsOnly">
                <span className="mr-10">
                  {moment(formik.values.saleDate).format("DD MMM YYYY")}
                </span>
              </div>
            </div>

            <div className="divColumn">
              <h4>Sales Tax</h4>
              <span>{saleData?.tax}%</span>
            </div>

            <Divider />
            <h4> Payment Details</h4>

            <div>
              <span>
                Payment : {currency}
                {saleData?.payments?.[0]?.paymentType}
              </span>
            </div>

            <div>
              <span>
                Customer Phone :{" "}
                {saleData?.payments?.[0]?.customerPhone && (
                  <>
                    {areaCode}
                    {saleData?.payments?.[0]?.customerPhone}
                  </>
                )}{" "}
                {!saleData?.payments?.[0]?.customerPhone && "-"}
              </span>
            </div>

            <Divider />

            <div>
              <h4>Merchant Notes</h4>
              <Input placeholder="Notes" {...formik.getFieldProps("notes")} />
            </div>
          </Flex>
          {items.length > 0 && (
            <Flex gap={20} vertical style={{ padding: 20, flex: 1 }}>
              <div className="flex-1">
                <h4>Items</h4>
                <List
                  itemLayout="horizontal"
                  dataSource={items}
                  bordered
                  renderItem={(item, index) => (
                    <List.Item>
                      <List.Item.Meta
                        title={item.title}
                        description={"$" + item.price?.value}
                      />
                    </List.Item>
                  )}
                />
              </div>

              <div className="divRight">
                <h1>
                  Total: {currency}
                  {total.toFixed(2)}
                </h1>
              </div>
            </Flex>
          )}
        </Flex>
      </Modal>
    </form>
  );
}
