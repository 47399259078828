import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { IAmount, IInvoiceCustomer, IUser } from "../types/types";
import { EXHUT_BIZ_LOCAL_STORAGE_TOKEN } from "../utils/constants";

export interface ICreateOrgPayload {
  name: string;
  website: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  country: string;
  zipCode: number;
  phone: string;
}

export function useCreateOrganization() {
  return _usePost<any, any, ICreateOrgPayload>({
    path: `organizations`,
    keys: ["organizations"],
    resetKeys: [],
  });
}

export interface ICreateInvoicePayload {
  total: number;
  tax: number;
  products: string[];
  customer?: IInvoiceCustomer;
  notes?: string;
}

export function useCreateInvoice(orgId?: string) {
  return _usePost<any, any, ICreateInvoicePayload>({
    path: `organizations/${orgId}/invoices`,
    keys: ["organizations", orgId!!, "invoices"],
    resetKeys: [],
  });
}
export interface ICreateOrgInvestmentPayload {
  category: string;
  amount: number;
  investmentDate: string;
  notes?: string;
}

export function useCreateOrgInvestment(orgId?: string) {
  return _usePost<any, any, ICreateOrgInvestmentPayload>({
    path: `organizations/${orgId}/investments`,
    keys: ["organizations", orgId!!, "investments"],
    resetKeys: ["organizations", orgId!!, "investments"],
  });
}

export interface ICreatePayloadSchedulePayload {
  startDate: string;
  schedule: string;
}

export function useCreatePayrollSchedule(orgId?: string) {
  return _usePost<any, any, ICreatePayloadSchedulePayload>({
    path: `organizations/${orgId}/payroll-schedules`,
    keys: ["organizations", orgId!!, "payroll-schedules"],
    resetKeys: ["organizations", orgId!!, "payroll-schedules"],
  });
}

export interface ICreatePayrollCyclePayload {
  type: string;
  startDate?: string;
  endDate?: string;
}

export function useCreatePayrollCycle(orgId?: string) {
  return _usePost<any, any, ICreatePayrollCyclePayload>({
    path: `organizations/${orgId}/payroll-cycles`,
    keys: ["organizations", orgId!!, "payroll-cycles"],
    resetKeys: ["organizations", orgId!!, "payroll-cycles"],
  });
}

interface ICreatePayrollPayload {
  empId?: number;
  cycleId: number;
}

export function useCreatePayroll(orgId?: string) {
  return _usePost<any, any, ICreatePayrollPayload>({
    path: `organizations/${orgId}/payroll`,
    keys: ["organizations", orgId!!, "payroll"],
    resetKeys: ["organizations", orgId!!, "payroll"],
  });
}

export function useCreatePayrollForEmployee(
  orgId?: string,
  cycleId?: string,
  empId?: number,
) {
  return _usePost<any, any, ICreatePayrollPayload>({
    path: `organizations/${orgId}/payroll`,
    keys: [
      "organizations",
      orgId!!,
      "payroll",
      cycleId!!,
      "payroll",
      empId?.toString()!!,
    ],
    resetKeys: [
      "organizations",
      orgId!!,
      "payroll-cycles",
      cycleId!!,
      "payroll",
      empId?.toString()!!,
    ],
    replacePathParams: "empId",
  });
}

export interface ICreatePayrollBonusPayload {
  empId: number;
  type: string;
  bonusType: string;
  amount: number;
}

export function useCreatePayrollBonus(orgId?: string, cycleId?: string) {
  return _usePost<any, any, ICreatePayrollBonusPayload>({
    path: `organizations/${orgId}/payroll-cycles/${cycleId}/bonuses`,
    keys: ["organizations", orgId!!, "payroll-cycles", cycleId!!, "bonuses"],
    resetKeys: [
      "organizations",
      orgId!!,
      "payroll-cycles",
      cycleId!!,
      "bonuses",
    ],
  });
}

export interface ICreatePaymentPayload {
  orderId?: number;
  invoiceId?: number;
  paymentType?: string;
  paymentDate: string;
  amount: number;
  customerPhone?: string;
  notes?: string;
}

export function useCreatePayment(orgId?: string) {
  return _usePost<any, any, ICreatePaymentPayload>({
    path: `organizations/${orgId}/payments`,
    keys: ["organizations", orgId!!, "payments"],
    resetKeys: ["organizations", orgId!!, "payments"],
  });
}

export interface ICreateOrgExpensePayload {
  category: string;
  amount: number;
  expenseDate: string;
  notes: string;
}

export function useCreateOrgExpense(orgId?: string) {
  return _usePost<any, any, ICreateOrgExpensePayload>({
    path: `organizations/${orgId}/expenses`,
    keys: ["organizations", orgId!!, "expenses"],
    resetKeys: [],
  });
}

export interface ICreateReimbursementPayload {
  category: string;
  amount: number;
  expenseDate: string;
  notes?: string;
}

export function useCreateReimbursement(orgId?: string) {
  return _usePost<any, any, ICreateReimbursementPayload>({
    path: `organizations/${orgId}/reimbursements`,
    keys: ["organizations", orgId!!, "reimbursements"],
    resetKeys: [],
  });
}

export interface ICreateReimbursementAttachmentPayload {
  reimbursementId?: string;
  attachment?: any;
}

export function useCreateAttachmentToReimbursement(orgId?: string) {
  const queryClient = useQueryClient();
  return useMutation<any, any, any>({
    mutationFn: async (req) => {
      const data = new FormData();
      if (req.attachment) {
        console.log("Attachment body is", req.attachment);

        data.append("attachment", req.attachment, req.attachment?.name);
      }

      console.log("Form data is ", data.entries());
      return axios
        .post(
          `${process.env.REACT_APP_BE_URL}/organizations/${orgId}/reimbursements/${req.reimbursementId}/attachments`,
          data,
          {
            headers: {
              authorization: localStorage.getItem(
                EXHUT_BIZ_LOCAL_STORAGE_TOKEN,
              ),
            },
          },
        )
        .then((resp) => resp.data);
    },
    mutationKey: ["organizations", orgId!!, "reimbursements"],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [] }),
  });
}

export interface IUpdateReimbursementStatusPayload {
  status: string;
  notes?: string;
  // following will be used by employee if he wants to update the reimbursement details
  expenseDate?: string;
  category?: string;
  amount?: number;
}

export function useUpdateReimbursement(id?: string, rid?: string) {
  return _usePost<any, any, IUpdateReimbursementStatusPayload>({
    path: `organizations/${id}/reimbursements/${rid}/status`,
    keys: ["organizations", id!!, "reimbursements", rid!!],
    resetKeys: [],
  });
}

interface ITimesheetPayload {
  timeSheetDate: string;
  hours: number;
  notes: string;
}

export function useCreateTimesheet(orgId?: string) {
  return _usePost<any, any, ITimesheetPayload>({
    path: `organizations/${orgId}/timesheets`,
    keys: ["organizations", orgId!!, "timesheets"],
    resetKeys: [],
  });
}

export interface IRewardLevelPayload {
  name: string;
  type: string;
  value: number;
  monetaryType: string;
  minAmount: number;
}

export function useCreateRewardLevel(orgId?: string) {
  return _usePost<any, any, IRewardLevelPayload>({
    path: `organizations/${orgId}/rewards-level`,
    keys: ["organizations", orgId!!, "rewards-level"],
    resetKeys: [],
  });
}

interface IRewardTransactionPayload {
  phone: string;
  billAmount?: number;
  levelId?: number;
}

export function useCreateReward(orgId?: string) {
  return _usePost<any, any, IRewardTransactionPayload>({
    path: `organizations/${orgId}/rewards`,
    keys: ["organizations", orgId!!, "rewards"],
    resetKeys: [],
  });
}

interface IRedemptionPayload {
  phone: string;
  rewards: number;
}

export function useCreateRedemption(orgId?: string) {
  return _usePost<any, any, IRedemptionPayload>({
    path: `organizations/${orgId}/redemption`,
    keys: ["organizations", orgId!!, "redemption"],
    resetKeys: [],
  });
}

export interface ICreateOrgSalePayload {
  category: string;
  amount: number;
  saleDate: string;
  notes?: string;
  paymentType?: string;
  phone?: string;
  products?: string[];
  tax: number;
}

export function useCreateOrgSale(orgId?: string) {
  return _usePost<any, any, ICreateOrgSalePayload>({
    path: `organizations/${orgId}/sales`,
    keys: ["organizations", orgId!!, "sales"],
    resetKeys: [],
  });
}

interface IAddEmployeePayload {
  email: string;
  startDate?: string;
  employmentType: string;
  salary: number;
}

export function useAddEmployeeToOrg(orgId?: string) {
  return _usePost<any, any, IAddEmployeePayload>({
    path: `organizations/${orgId}/employees`,
    keys: ["organizations", orgId!!, "employees"],
    resetKeys: [],
  });
}

interface IAddDeductionPayload {
  name: string;
  type: string;
  value: number;
}

export function useAddDeductionToEmployee(orgId?: string, empId?: string) {
  return _usePost<any, any, IAddDeductionPayload>({
    path: `organizations/${orgId}/employees/${empId}/deductions`,
    keys: ["organizations", orgId!!, "employees", empId!!, "deductions"],
    resetKeys: [],
  });
}

export interface IAuthenticatePayload {
  email: string;
  password: string;
}

export type IRegisterPayload = IAuthenticatePayload;

export type IAuthenticatResponse = IUser;

export function useRegisterMutation() {
  return _usePostAuthBE<void, undefined, IRegisterPayload>(
    "register?source=business",
    ["register"],
    [""],
    false,
  );
}

export interface IRegisterSSOPayload {
  token: string;
}

export function useRegisterSSOMutation() {
  return _usePostAuthBE<IAuthenticatResponse, undefined, IRegisterSSOPayload>(
    "register/sso?source=business",
    [],
    [],
    false,
  );
}

export function useAuthenticate() {
  return _usePostAuthBE<IAuthenticatResponse, undefined, IAuthenticatePayload>(
    "auth",
    [],
    [],
    false,
  );
}

export interface IRegisterVerifyPayload {
  cid: string;
  regkey: string;
}

export function useRegisterVerify() {
  return _usePostAuthBE<
    IAuthenticatResponse,
    undefined,
    IRegisterVerifyPayload
  >("register/verify", [], [], false);
}

export interface IAuthenticateSSOPayload {
  token: string;
  type: "google";
}

export function useAuthenticateWithSSO() {
  return _usePostAuthBE<IAuthenticatResponse, null, IAuthenticateSSOPayload>(
    `auth/sso`,
    [],
    [],
  );
}

export function usePostLogout() {
  return _usePostAuthBE<void, null, void>("logout", [], []);
}

function _usePostAuthBE<T1, T2, T3>(
  path: string,
  keys: string[],
  resetKeys: string[],
  authenticated = true,
) {
  return _usePost<T1, T2, T3>({
    path,
    keys,
    resetKeys,
    authenticated,
    host: process.env.REACT_APP_AUTH_BE_URL,
  });
}

export interface ICreateProductPayload {
  title: string;
  descriptions?: string;
  price: IAmount;
  externalId?: string;
  sku?: string;
  quantity?: number;
}

export function useCreateProduct(orgId?: string) {
  return _usePost<null, null, ICreateProductPayload>({
    path: `organizations/${orgId}/products`,
    keys: ["products"],
    resetKeys: ["products"],
    authenticated: true,
    host: process.env.REACT_APP_PRODUCTS_URL,
  });
}

export interface ICreateProductImagePayload {
  image: string;
}

export function useCreateProductImage(orgId?: string, productId?: string) {
  return _usePost<null, null, ICreateProductImagePayload>({
    path: `organizations/${orgId}/products/${productId}/images`,
    keys: ["products", productId!!, "images"],
    resetKeys: ["products", productId!!, "images"],
    authenticated: true,
    host: process.env.REACT_APP_PRODUCTS_URL,
  });
}

function _usePost<T1, T2, T3>({
  path,
  keys,
  resetKeys,
  authenticated = true,
  host,
  replacePathParams,
}: {
  path: string;
  keys?: string[];
  resetKeys?: string[];
  authenticated?: boolean;
  host?: string;
  replacePathParams?: string;
}) {
  let _url = host
    ? `${host}/${[path]}`
    : `${process.env.REACT_APP_BE_URL}/${path}`;

  let headers: any = {
    "Content-Type": "application/json",
  };

  if (authenticated) {
    headers["Authorization"] = localStorage.getItem(
      EXHUT_BIZ_LOCAL_STORAGE_TOKEN,
    );
  } else {
    headers["Access-Type"] = "offline";
  }

  const queryClient = useQueryClient();
  return useMutation<T1, T2, T3>({
    mutationFn: async (req) => {
      if (replacePathParams) {
        _url = _url.replace(
          `:${replacePathParams}`,
          (req as any)[replacePathParams],
        );
      }
      return axios
        .post(_url, req, {
          headers,
        })
        .then((resp) => resp.data);
    },
    mutationKey: keys,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: resetKeys }),
  });
}
