import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Flex,
  Input,
  Modal,
  Select,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import { useFormik } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  useCreateAttachmentToReimbursement,
  useCreateReimbursement,
} from "../../../api/api";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { useCurrency } from "../../../hooks/useCurrency";
import { toSQL } from "../../../utils/date.utils";
import { reimbursementCategories } from "../../../utils/reimbursements";

export default function AddReimbursementModal({
  open,
  onCancel,
}: {
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const { currency } = useCurrency();
  const { notifySuccess, notification, notifyError } = useNotifications({
    title: "Success",
    subtitle: "Expense reimbursement created successfully.",
  });
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const { mutateAsync, isPending } = useCreateReimbursement(id);
  const {
    mutateAsync: createAttachmentAsync,
    isPending: isAttachmentUploadPending,
  } = useCreateAttachmentToReimbursement(id);
  const [messageApi, contextHolder] = message.useMessage();

  const formik = useFormik({
    initialValues: {
      category: "",
      amount: "",
      expenseDate: "",
      notes: "",
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Please select a category"),
      expenseDate: Yup.string().required("Please select date"),
      amount: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid salary")
        .required("Please enter amount"),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        ...values,
        amount: +values.amount,
        expenseDate: toSQL(formik.values.expenseDate),
      })
        .then((result) => {
          if (fileList.length > 0) {
            createAttachmentAsync({
              reimbursementId: result.id,
              attachment: fileList[0],
            })
              .then(() => {
                notifySuccess();
                onCancel();
              })
              .catch((err) => {
                console.error(err);
                notifyError();
              });
          }
          notifySuccess();
          onCancel();
        })
        .catch(() => {
          notifyError();
        });
    },
  });

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);

      if (file.size > 10045377) {
        messageApi.open({
          type: "error",
          content: "Please add attachment less than 4MB",
        });
      }
      return false;
    },
    fileList,
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Add Reimbursement Details"
        open={open}
        onOk={formik.submitForm}
        onCancel={() => {
          formik.resetForm();
          onCancel();
        }}
        okText={"Create"}
        okButtonProps={{ loading: isPending || isAttachmentUploadPending }}
      >
        {notification}
        {contextHolder}

        <Flex gap={30} vertical>
          <div>
            <Select
              showSearch
              style={{ minWidth: 200 }}
              placeholder="Select a category"
              optionFilterProp="label"
              onChange={(value, option) =>
                formik.setFieldValue("category", (option as any).label)
              }
              options={reimbursementCategories}
            />

            <ErrorPanel message={formik.errors.category} />
          </div>

          <div>
            <Input
              prefix={currency}
              placeholder="Amount"
              {...formik.getFieldProps("amount")}
            />
            <ErrorPanel message={formik.errors.amount} />
          </div>

          <div>
            <DatePicker
              value={formik.values.expenseDate}
              onChange={(value) => formik.setFieldValue("expenseDate", value)}
            />

            <ErrorPanel message={formik.errors.expenseDate} />
          </div>

          <div>
            <Input placeholder="Notes" {...formik.getFieldProps("notes")} />
          </div>

          <Upload
            {...props}
            maxCount={1}
            accept="image/png,image/jpeg,application/pdf"
          >
            <Button disabled={fileList.length > 0} icon={<UploadOutlined />}>
              Click to Upload
            </Button>
          </Upload>
        </Flex>
      </Modal>
    </form>
  );
}
