import {
  Alert,
  Avatar,
  Button,
  Checkbox,
  Flex,
  Input,
  Layout,
  Select,
} from "antd";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useCreateOrganization } from "../../api/api";
import ErrorPanel from "../../components/error.panel.component";
import { ROUTES } from "../../utils/constants";

const { Content } = Layout;

interface IValues {
  name?: string;
  website?: string;
}

export default function CreateOrg() {
  const [next, setNext] = useState<boolean>(false);
  const [values, setValues] = useState<IValues>();

  return (
    <div className="mt-40">
      <div className={`divCenterAlign divColumn p-32`}>
        {!next && (
          <CreateOrg1
            name={values?.name}
            website={values?.website}
            onSuccess={(values: IValues) => {
              setValues(values);
              setNext(true);
            }}
          />
        )}

        {next && (
          <CreateOrg2
            name={values?.name}
            website={values?.website}
            onBack={() => setNext(false)}
          />
        )}
      </div>
    </div>
  );
}

function CreateOrg1({
  onSuccess,
  name,
  website,
}: {
  onSuccess: (values: IValues) => void;
  name?: string;
  website?: string;
}) {
  const formik = useFormik({
    initialValues: {
      name,
      website,
      websiteDevelopment: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      website: Yup.string().when("websiteDevelopment", {
        is: false,
        then: (schema) =>
          schema
            .matches(/^https/, "Should start https://")
            .url("Please enter valid url")
            .required("Website cannot be empty"),
      }),
    }),
    onSubmit: async (values) => {
      onSuccess(values);
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={`divColumn p-32`}>
          <Content
            className="p-60"
            style={{ minWidth: "40%", boxShadow: "3px 1px 5px 5px #e6ddd6" }}
          >
            <div className="w-100">
              <div className="mb-80">
                <div className="title mb-10">Business</div>
              </div>

              <div className="divCenterAlign my-0">
                <Avatar
                  style={{ width: 56, height: 56 }}
                  src={"/assets/icons/cityscape.png"}
                />
              </div>

              <div className="mt-80">
                <form onSubmit={formik.handleSubmit}>
                  <Flex vertical>
                    <h4>Business Name</h4>
                    <div className="divColumn">
                      <Input
                        maxLength={100}
                        {...formik.getFieldProps("name")}
                      />

                      <ErrorPanel message={formik.errors.name} />
                    </div>
                  </Flex>

                  <Flex vertical className="mb-40">
                    <h4>Website</h4>

                    <div className="divColumn">
                      <Input {...formik.getFieldProps("website")} />
                      <ErrorPanel message={formik.errors.website} />
                    </div>

                    <Checkbox {...formik.getFieldProps("websiteDevelopment")}>
                      Our website is under development
                    </Checkbox>
                  </Flex>

                  <Button type="primary" onClick={formik.submitForm}>
                    Next
                  </Button>
                </form>
              </div>
            </div>
          </Content>
        </div>
      </form>
    </div>
  );
}

function CreateOrg2({
  name,
  website,
  onBack,
}: {
  name?: string;
  website?: string;
  onBack: () => void;
}) {
  const { mutateAsync } = useCreateOrganization();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: name!!,
      website: website!!,
      street1: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      phone: "",
      areaCode: "",
    },
    validationSchema: Yup.object({
      name: Yup.string(),
      website: Yup.string(),
      street1: Yup.string().required("Please enter name"),
      city: Yup.string().required("Please enter city"),
      state: Yup.string().required("Please enter state"),
      country: Yup.string().required("Please select a country"),
      zipCode: Yup.string()
        .matches(/^[0-9]+$/, "Zip code or pin code must be digits")
        .required("Please enter zip.")
        .min(5, "Invalid zipcode")
        .max(7, "Invalid zipcode"),
      phone: Yup.string()
        .matches(/^[0-9]+$/, "Phone number must be digits")
        .required("Please enter phone")
        .min(10, "Invalid phone number")
        .max(10, "Invalid phone number"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      mutateAsync({
        ...values,
        zipCode: +values.zipCode,
        phone: `${formik.values.areaCode}${formik.values.phone}`,
      })
        .then(() => {
          navigate(ROUTES.HOME);
        })
        .catch((err) => {
          if (err.response.data.message === "Address is invalid") {
            setErrorMsg(
              "Unable to verify address. Please double check if you have entered full address including house or unit number.",
            );
          } else {
            setErrorMsg("Whoops! Unable to submit data. Please try again.");
          }
        })
        .finally(() => setIsLoading(false));
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className={` divColumn p-32`}>
          <div className="card p-60">
            <div className="">
              <div className="mb-80">
                <div className="title mb-10">Business</div>
              </div>

              <div className=" my-0">
                <Avatar
                  style={{ width: 56, height: 56 }}
                  src={"/assets/icons/cityscape.png"}
                />
              </div>

              <div className="mt-80">
                <form onSubmit={formik.handleSubmit}>
                  <Flex vertical>
                    <h4>Street 1</h4>

                    <Input
                      maxLength={50}
                      style={{ width: 300 }}
                      {...formik.getFieldProps("street1")}
                    />
                    <ErrorPanel message={formik.errors.street1} />
                  </Flex>

                  <Flex vertical>
                    <h4>Street 2</h4>

                    <Input
                      maxLength={50}
                      {...formik.getFieldProps("street2")}
                    />
                    <ErrorPanel message={formik.errors.street2} />
                  </Flex>

                  <Flex vertical>
                    <h4>City</h4>

                    <Input maxLength={50} {...formik.getFieldProps("city")} />
                    <ErrorPanel message={formik.errors.city} />
                  </Flex>

                  <Flex vertical>
                    <h4>State</h4>

                    <Input maxLength={25} {...formik.getFieldProps("state")} />
                    <ErrorPanel message={formik.errors.state} />
                  </Flex>

                  <Flex vertical>
                    <h4>Country</h4>

                    <Select
                      onChange={(value) =>
                        formik.setFieldValue("country", value)
                      }
                      style={{ width: "auto" }}
                      options={[
                        { value: "CAN", label: "Canada" },
                        { value: "IND", label: "India" },
                        { value: "SGP", label: "Singapore" },

                        { value: "UK", label: "United Kingdom" },
                        { value: "USA", label: "USA" },
                      ]}
                    />
                    <ErrorPanel message={formik.errors.country} />
                  </Flex>

                  <Flex vertical>
                    <h4>Zipcode</h4>
                    <Input
                      maxLength={10}
                      {...formik.getFieldProps("zipCode")}
                    />
                    <ErrorPanel message={formik.errors.zipCode} />
                  </Flex>

                  <Flex vertical>
                    <h4>Phone</h4>

                    <Input
                      prefix={formik.values.areaCode}
                      maxLength={15}
                      {...formik.getFieldProps("phone")}
                    />
                    <ErrorPanel message={formik.errors.phone} />
                  </Flex>

                  {errorMsg && (
                    <Alert
                      type="error"
                      message={errorMsg}
                      style={{ width: 300 }}
                    />
                  )}

                  <Flex vertical gap={40} className="my-40">
                    <Button
                      type="primary"
                      onClick={formik.submitForm}
                      loading={isLoading}
                    >
                      Submit
                    </Button>
                    <Button onClick={() => onBack()}>Back</Button>
                  </Flex>
                </form>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
