import { Flex, Input, InputRef, Modal, Select, Typography } from "antd";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateReward } from "../../../api/api";
import {
  useGetPointsRewardLevels,
  useGetRewardLevelsById,
} from "../../../api/api.get";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { getCountryPhoneCode } from "../../../utils/constants";
import { REWARD_MONETARY_TYPE } from "./add-reward-level-modal";

const { Title } = Typography;

export default function AddRewardModal({
  open,
  onCancel,
  countryCode,
}: {
  open: boolean;
  onCancel: () => void;
  countryCode?: string;
}) {
  const { id } = useParams();
  const textInputRef = useRef<InputRef>(null);
  const { mutateAsync, isPending } = useCreateReward(id);

  const { notifySuccess, notifyError } = useNotifications();

  const areaCode = getCountryPhoneCode(countryCode);

  const [rewardsOptions, setRewardsOptions] = useState<any[]>([]);

  const { data: rewardLevelData } = useGetPointsRewardLevels(id);

  useEffect(() => {
    if (textInputRef.current) {
      setTimeout(() => {
        textInputRef.current?.focus();
      }, 500);
    }
  }, [open]);

  useEffect(() => {
    if (rewardLevelData) {
      const options: any[] = [
        {
          label: "--Select--",
          value: "",
        },
      ];
      for (const r of rewardLevelData) {
        options.push({
          label: r.name,
          value: r.id,
        });
      }
      setRewardsOptions(options);
    }
  }, [rewardLevelData]);

  const formik = useFormik({
    initialValues: {
      phone: "",
      levelId: undefined,
      levelType: undefined,
      billAmount: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string().required("Please add customer phone number"),
      levelId: Yup.string().required("Please select reward type"),
      levelType: Yup.string(),
      billAmount: Yup.string().when("levelType", {
        is: "percent",
        then: (schema) =>
          schema
            .matches(
              /^(0|[1-9]\d*)(\.\d+)?$/,
              "Please enter numbers only. Do not include % or currency symbols",
            )
            .typeError("Please enter valid number.")
            .required("Plese enter bill amount"),
      }),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        ...values,
        phone: `${areaCode}${values.phone}`,
        billAmount: +values.billAmount,
      })
        .then(() => {
          notifySuccess();
          formik.resetForm();
          onCancel();
        })
        .catch((err) => {
          if (err.response.data?.statusCode === 404) {
            notifyError();
          } else {
            notifyError();
          }
        });
    },
  });

  const { data: levelDetails } = useGetRewardLevelsById(
    id,
    formik.values.levelId,
  );

  useEffect(() => {
    if (formik.values.levelId) {
      formik.setFieldValue("levelType", levelDetails?.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.levelId]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Grant Reward to Customer"
        open={open}
        onOk={formik.submitForm}
        okText={"Add"}
        onCancel={() => {
          formik.resetForm();
          onCancel();
        }}
        okButtonProps={{
          disabled: isPending,
          loading: isPending,
        }}
      >
        <Flex gap={30} vertical>
          <div>
            <p className="infoMsg">Customer phone number</p>

            <Input prefix={areaCode} {...formik.getFieldProps("phone")} />
            <ErrorPanel message={formik.errors.phone} />
          </div>

          <div className="divColumn">
            <strong>Select Points Coupon</strong>
            <Select
              style={{ minWidth: 200 }}
              value={formik.values.levelId}
              onChange={(value) => formik.setFieldValue("levelId", value)}
              options={rewardsOptions}
              notFoundContent="No POINTS coupons available"
            />
            <ErrorPanel message={formik.errors.levelId} />
          </div>
          {levelDetails &&
            levelDetails.monetaryType ===
              REWARD_MONETARY_TYPE.PERCENT.toString() && (
              <div>
                <p className="infoMsg">Total Bill Amount</p>
                <Input {...formik.getFieldProps("billAmount")} />
                <ErrorPanel message={formik.errors.billAmount} />
              </div>
            )}
          <Flex vertical>
            <span className="infoMsg">Rewards Points:</span>
            <Title level={3}>
              {levelDetails?.monetaryType ===
              REWARD_MONETARY_TYPE.PERCENT.toString()
                ? Math.round(
                    (levelDetails?.value / 100) * +formik.values.billAmount,
                  )
                : levelDetails?.value}
            </Title>
          </Flex>
        </Flex>
      </Modal>
    </form>
  );
}
