import { Link } from "react-router-dom";

export default function LinkComponent({
  path,
  title,
}: {
  path: string;
  title: string;
}) {
  return (
    <Link style={{ color: "var(--exhut-total-green)" }} to={path}>
      {title}
    </Link>
  );
}
