import { DeleteFilled, LoadingOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  AutoCompleteProps,
  Button,
  Flex,
  Input,
  List,
  Spin,
} from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import * as Yup from "yup";
import { ICreateInvoicePayload, useCreateInvoice } from "../../../api/api";
import { useSearchProducts } from "../../../api/api.get";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { useCurrency } from "../../../hooks/useCurrency";
import { IProduct } from "../../../types/types";

export default function AddInvoice() {
  const { id } = useParams();
  const { areaCode } = useCurrency();
  const { notifySuccess, notification, notifyError } = useNotifications({
    title: "Success",
    subtitle: "Invoice created successfully.",
  });
  const [servicesSearchText, setServicesSearchText] = useState<string>("");
  const [debouncedSearchText] = useDebounce(servicesSearchText, 300);
  const [options, setOptions] = useState<AutoCompleteProps["options"]>([]);
  const [items, setItems] = useState<IProduct[]>([]);

  const navigate = useNavigate();
  const { data, isLoading } = useSearchProducts(id, debouncedSearchText);

  const { mutateAsync, isPending } = useCreateInvoice(id);

  const formik = useFormik({
    initialValues: {
      total: 0,
      tax: "",
      customerFirstName: "",
      customerLastName: "",
      customerStreet1: "",
      customerStreet2: "",
      customerCity: "",
      customerState: "",
      customerCountryCode: "",
      customerPhone: "",
      customerEmail: "",
      customerZipCode: "",
      products: [],
      notes: "",
    },
    validationSchema: Yup.object({
      total: Yup.number(),
      products: Yup.array().required("Please add products or services"),
      tax: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid number")
        .required("Please enter valid tax percentage"),
      customerPhone: Yup.string().matches(
        /^(0|[1-9]\d*)(\.\d+)?$/,
        "Please enter valid phone number",
      ),
      customerEmail: Yup.string(),
      customerZipCode: Yup.string().matches(
        /^(0|[1-9]\d*)(\.\d+)?$/,
        "Please enter valid zip code",
      ),
    }),
    onSubmit: async (values) => {
      const payload: ICreateInvoicePayload = {
        products: values.products,
        tax: +values.tax,
        total: values.total,
        notes: values.notes,
        customer: {
          firstName: values.customerFirstName,
          lastName: values.customerLastName,
          email: values.customerEmail,
          phone: values.customerPhone,
          address: {
            street1: values.customerStreet1,
            street2: values.customerStreet2,
            city: values.customerCity,
            state: values.customerState,
            countryCode: values.customerCountryCode,
            zipCode: values.customerZipCode,
          },
        },
      };

      mutateAsync(payload)
        .then(() => {
          formik.resetForm();
          setItems([]);
          notifySuccess();
        })
        .catch(() => {
          notifyError();
        });
    },
  });

  useEffect(() => {
    if (data) {
      setOptions(
        data.map((product: IProduct) => {
          return {
            value: product.title,
            title: product.title,
            id: product._id,
            price: product.price,
          };
        }),
      );
    }
  }, [data]);

  const handleAddItems = useCallback(
    (option: DefaultOptionType) => {
      if (options) {
        const temp = [...items];
        temp.push({
          _id: option.id,
          title: option.title ?? "",
          price: option.price,
          createdAt: option.createdAt,
        });
        setItems(temp);
        const ids: string[] = temp.map((p: IProduct) => p._id);
        formik.setFieldValue("products", ids);
        setServicesSearchText("");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options],
  );

  const handleDeleteItems = (_item: Partial<IProduct>) => {
    const temp = [...items];
    const index = temp.findIndex((item) => item._id === _item._id);
    if (index >= 0) {
      temp.splice(index, 1);
    }
    const ids: string[] = temp.map((p: IProduct) => p._id);
    formik.setFieldValue("products", ids);
    setItems(temp);
  };

  useEffect(() => {
    let itemTotal = items.reduce(
      (acc, item) => acc + (item.price?.value ?? 0),
      0,
    );
    if (+formik.values.tax > 0) {
      itemTotal += (+formik.values.tax / 100) * itemTotal;
    }
    formik.setFieldValue("total", itemTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, formik.values.tax]);

  const [isAddMode, setIsAddMode] = useState(false);

  return (
    <div style={{ marginBottom: 100 }} className="divColumn mx-40 my-40 flex-1">
      <div className="divRight mb-40">
        <Button
          type="link"
          onClick={() => navigate(`/organizations/${id}/invoices`)}
        >
          Back
        </Button>

        <Button onClick={formik.submitForm} loading={isPending} type="primary">
          Create
        </Button>
      </div>

      <div className="card p-40">
        <div className="divSpread divAlignItemsOnly">
          <h1>Invoice</h1>

          {!isAddMode && (
            <Button onClick={() => setIsAddMode(true)} type="link">
              Add Items
            </Button>
          )}

          {isAddMode && (
            <AutoComplete
              size="large"
              options={options}
              style={{ width: 300 }}
              value={servicesSearchText}
              onSelect={(_, option) => handleAddItems(option)}
              onSearch={(text) => setServicesSearchText(text)}
              placeholder="Search services or products..."
              notFoundContent={"No results found."}
            />
          )}
        </div>

        <Flex
          className=" mt-80"
          style={{ border: "", padding: 0, borderRadius: 10 }}
          gap={30}
          vertical
        >
          <div className="flex-1"></div>

          <div className="flex-1 w-60">
            {items.length > 0 && (
              <List
                itemLayout="horizontal"
                bordered
                dataSource={items}
                renderItem={(item, index) => (
                  <List.Item
                    actions={[
                      <DeleteFilled
                        onClick={() => handleDeleteItems(item)}
                        style={{ color: "var(--exhut-light-red)" }}
                      >
                        delete
                      </DeleteFilled>,
                    ]}
                  >
                    <List.Item.Meta
                      title={item.title}
                      description={"$" + item.price?.value}
                    />
                  </List.Item>
                )}
              />
            )}
          </div>
        </Flex>

        <form onSubmit={formik.handleSubmit}>
          {notification}
          <Flex gap={30} vertical className="mt-40">
            <div>
              <h4>Tax</h4>
              <Input
                size="middle"
                prefix={"%"}
                placeholder="Taxes"
                {...formik.getFieldProps("tax")}
              />
              <ErrorPanel message={formik.errors.tax} />
            </div>

            <div className="mt-40">
              <span>Total</span>
              <h1> ${formik.values.total.toFixed(2)}</h1>
              {isLoading && (
                <Spin indicator={<LoadingOutlined spin />} size="large" />
              )}
            </div>

            <div>
              <h4 className="mb-40">Customer Details</h4>

              <Flex gap={50}>
                <Flex gap={30} vertical>
                  <Flex gap={10} vertical>
                    <span>First Name</span>
                    <Input {...formik.getFieldProps("customerFirstName")} />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>Last Name</span>
                    <Input {...formik.getFieldProps("customerLastName")} />
                  </Flex>
                  <Flex gap={10} vertical>
                    <span>Email</span>
                    <Input {...formik.getFieldProps("customerEmail")} />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>Phone</span>
                    <Input
                      prefix={areaCode}
                      {...formik.getFieldProps("customerPhone")}
                    />
                    <ErrorPanel message={formik.errors.customerPhone} />
                  </Flex>
                </Flex>

                <Flex gap={30} vertical>
                  <Flex gap={10} vertical>
                    <span>Street 1</span>
                    <Input {...formik.getFieldProps("customerStreet1")} />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>Street 2</span>
                    <Input {...formik.getFieldProps("customerStreet2")} />
                  </Flex>
                  <Flex gap={10} vertical>
                    <span>City</span>
                    <Input {...formik.getFieldProps("customerCity")} />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>State</span>
                    <Input {...formik.getFieldProps("customerState")} />
                  </Flex>
                </Flex>

                <Flex gap={30} vertical>
                  <Flex gap={10} vertical>
                    <span>Country</span>
                    <Input {...formik.getFieldProps("customerCountryCode")} />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>Zip Code</span>
                    <Input {...formik.getFieldProps("customerZipCode")} />
                    <ErrorPanel message={formik.errors.customerZipCode} />
                  </Flex>
                </Flex>
              </Flex>
            </div>
          </Flex>

          <div>
            <h4>Notes</h4>

            <Input.TextArea
              placeholder="Notes"
              {...formik.getFieldProps("notes")}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
