import { Alert, Divider, Row } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Audio } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import {
  useGetOrganizationById,
  useGetProfileOnOrganization,
  useGetReimbursements,
  useGetTimeSheetsTotal,
} from "../../api/api.get";
import { useLayoutContext } from "../../providers/layout.provider";
import { IProfileDetails } from "../../types/types";
import { getCurrencySymbol } from "../../utils/functions";
import BusinessOwnerSection, { Card } from "./home/owner-section.component";
import OrganizationDate from "./organization-date";

export default function OrganizationHomePage() {
  const { id: orgId } = useParams();
  const {
    data: profile,
    error,
    isLoading,
  } = useGetProfileOnOrganization(orgId);
  const { isMobile } = useLayoutContext();

  return (
    <div
      style={{ marginBottom: 100, minHeight: 600 }}
      className="p-40 divColumn flex-1"
    >
      {isLoading && (
        <div className="divCenterAlign">
          <Audio
            height="100"
            width="100"
            color="#4fa94d"
            ariaLabel="audio-loading"
            wrapperStyle={{}}
            wrapperClass="wrapper-class"
            visible={true}
          />
        </div>
      )}

      {!isLoading && (
        <div className={isMobile ? "divColumn" : "divFlex"}>
          <div style={{ flex: 1 }}>
            {profile && (
              <>
                {profile?.type === "admin" && <BusinessOwnerSection />}

                {profile?.type === "employee" && (
                  <EmployeeSection profile={profile} />
                )}
              </>
            )}

            {(error || !profile) && (
              <Alert
                message="Whoops! Unable to fetch your profile. Please try aftr some time."
                type="warning"
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function EmployeeSection({ profile }: { profile: IProfileDetails }) {
  const { id } = useParams();
  const [date, setDate] = useState(moment());
  const { data: orgData } = useGetOrganizationById(id);

  const currency = useMemo(
    () => getCurrencySymbol(orgData?.country),
    [orgData],
  );

  const { data: timesheetTotal, refetch } = useGetTimeSheetsTotal(
    id,
    date.startOf("month").format("YYYY-MM-DD"),
    date.endOf("month").format("YYYY-MM-DD"),
  );
  const { data: reimbursements, refetch: refetchReimbursements } =
    useGetReimbursements(
      id,
      date.startOf("month").format("YYYY-MM-DD"),
      date.endOf("month").format("YYYY-MM-DD"),
    );

  useEffect(() => {
    if (date) {
      refetch();
      refetchReimbursements();
    }
  }, [date, refetch, refetchReimbursements]);

  const salary = useMemo(() => {
    if (profile.employmentType === "salaried") {
      return (profile?.salary ?? 0) / 12;
    }

    if (timesheetTotal) {
      return timesheetTotal.total * profile.salary;
    }

    return 0;
  }, [profile, timesheetTotal]);

  return (
    <div className="divColumn mb-40 flex-1 vh-100">
      <div className="divCenterAlign">
        <OrganizationDate onChange={(d) => setDate(moment(d, "MMM YYYY"))} />
      </div>

      {!!profile?.employmentEndDate && (
        <div className="divCenterAlign my-20">
          <Alert
            message={`Your employment ended on ${moment(profile.employmentEndDate).format("Do MMM YYYY")}`}
            type="error"
          />
        </div>
      )}

      <Divider />

      <Row gutter={[20, 20]} className="mt-40">
        <Card
          value={salary ?? 0}
          currency={currency}
          title={"Gross Monthly Pay"}
          id="gross-monthly-pay"
        />

        {profile.employmentType === "hourly" && (
          <Card
            type="none"
            value={timesheetTotal?.total ?? 0}
            title={"Hours this month"}
            link={`/organizations/${id}/timesheets`}
            id="clocked-hours-total"
          />
        )}

        <Card
          value={reimbursements?.length ?? 0}
          type="none"
          title={"Reimbursements"}
          link={`/organizations/${id}/reimbursements`}
        />
      </Row>
    </div>
  );
}
