import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useGetSalesAggregation } from "../../../api/api.get";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { Empty, Typography } from "antd";

export default function SalesChartComponent() {
  const { id } = useParams();

  const { data: salesData } = useGetSalesAggregation(id);
  const [data, setData] = useState<any[][]>([]);

  useEffect(() => {
    if (salesData) {
      const results: any[] = [["Month", "Sales"]];
      for (const item of salesData) {
        const date = moment(item.month_year, "YYYYM").format("MMM YYYY");
        results.push([date, item.total]);
      }
      setData(results);
    }
  }, [salesData]);

  /**
   *   const data = [
        ["Month", "Sales"],
        ["Oct 2024", 18000],
        ["Sept 2024", 6500],
        ["Aug 2024", 12000],
        ["July 2024", 4500],
        ["Jun 2024", 9500],
        ["May 2024", 2600],
        ["Apr 2024", 2500],
        ["Mar 2024", 1800],
        ["Feb 2024", 2800],
        ["Jan 2024", 2200],
      ];
   */

  const options = {
    chartArea: { width: "50%" },
    hAxis: {
      title: "",
      minValue: 0,
    },
    orientation: "horizontal",
    vAxis: {
      title: "Total Sales",
    },
  };

  return (
    <>
      {salesData && salesData.length > 0 && (
        <Chart
          // Bar is the equivalent chart type for the material design version.
          chartType="BarChart"
          width="100%"
          height="400px"
          data={data}
          options={options}
        />
      )}
      {!salesData ||
        (salesData?.length <= 0 && (
          <Empty
            description={
              <Typography.Text>
                Add <Link to={`/organizations/${id}/sales`}>Sales</Link>
              </Typography.Text>
            }
          />
        ))}
    </>
  );
}
