import { Button, Flex, Modal, Table } from "antd";
import { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";

import { useParams } from "react-router-dom";
import {
  useGetOrganizationById,
  useSearchProductsByMultipleIds,
} from "../../../api/api.get";
import { useCurrency } from "../../../hooks/useCurrency";
import { IInvoice } from "../../../types/types";
import { capitalize } from "../../../utils/functions";

export default function ViewExpenseModal({
  open,
  onCancel,
  invoice,
}: {
  invoice?: IInvoice;
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const { currency } = useCurrency();
  const { data: orgData } = useGetOrganizationById(id);
  const { data: searchByMulitpleProductsData, refetch } =
    useSearchProductsByMultipleIds(id, invoice?.products);
  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({
    contentRef,
  });

  useEffect(() => {
    if (open) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const columns = [
    {
      title: "Item",
      key: "name",
      dataIndex: "name",
      render: (row: any) => <span>{capitalize(row)}</span>,
    },
    {
      title: "Price",
      render: (row: any) => (
        <span>
          {currency}
          {row.price.value}
        </span>
      ),
    },
  ];

  const total = searchByMulitpleProductsData?.reduce(
    (acc, item) => acc + (item.price?.value ?? 0),
    0,
  );

  useEffect(() => {});

  return (
    <Modal
      title="Invoice"
      open={open}
      onOk={onCancel}
      onCancel={onCancel}
      footer={null}
    >
      <div className="divRight">
        <Button type="link" onClick={() => handlePrint()}>
          Print
        </Button>
      </div>

      <Flex gap={10} vertical style={{ padding: "40px 40px" }} ref={contentRef}>
        <div>
          <div className="divSpread divAlignItemsOnly">
            <h2>INVOICE</h2>
            <div className="small-font">No. {invoice?.externalId}</div>
          </div>

          <div className="my-10">
            <div className="divColumn">
              <strong>{capitalize(orgData?.name)}</strong>
              <span>{orgData?.street1}</span>
              <span>{orgData?.street2}</span>
              <span>{orgData?.city}</span>
              <span>
                {orgData?.state} {orgData?.country} {orgData?.zipCode}
              </span>
            </div>
          </div>
        </div>

        <hr style={{ border: "1 solid black" }} />

        <div>
          <h4> BILL TO </h4>
          <div>
            <div className="my-10">
              <div className="divColumn">
                {(invoice?.customer?.firstName ||
                  invoice?.customer?.lastName) && (
                  <strong>
                    {capitalize(invoice?.customer?.firstName)}{" "}
                    {capitalize(invoice?.customer?.lastName)}
                  </strong>
                )}

                <span>{invoice?.customer?.address?.street1}</span>
                <span>{invoice?.customer?.address?.street2}</span>
                <span>{invoice?.customer?.address?.city}</span>
                <span>
                  <span>
                    {invoice?.customer?.address?.state}
                    {invoice?.customer?.address?.countryCode}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div className="my-5">
            <div>
              <strong>Email</strong>
              <span className="mx-10">{invoice?.customer?.email}</span>
            </div>

            <div>
              <strong>Phone</strong>
              <span className="mx-10">{invoice?.customer?.phone}</span>
            </div>
          </div>
        </div>
        <hr style={{ border: "1 solid black" }} />

        <div className="my-20">
          <h3>Services</h3>
          <Table
            bordered
            dataSource={searchByMulitpleProductsData}
            columns={columns}
            pagination={{ hideOnSinglePage: true }}
          />
        </div>
        <hr style={{ border: "1 solid black" }} />

        <Flex gap={20} vertical className="divRight my-40">
          <div className="divRight">
            <strong>Net Total</strong>
            <span className="mx-10">
              {currency}
              {total}
            </span>
          </div>
          <div className="divRight">
            <strong>Tax</strong>
            <span className="mx-10">{invoice?.tax}%</span>
          </div>

          <div className="divRight">
            <strong>Total</strong>
            <span className="mx-10">
              {currency}
              {invoice?.total}
            </span>
          </div>
        </Flex>

        <div className="my-40">
          <strong className="mx-10">Notes:</strong>
          {invoice?.notes}
        </div>
      </Flex>
    </Modal>
  );
}
