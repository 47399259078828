import {
  Button,
  DatePicker,
  Flex,
  Input,
  Modal,
  Select,
  Table,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import Alert from "antd/es/alert/Alert";
import { useFormik } from "formik";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  useCreateAttachmentToReimbursement,
  useUpdateReimbursement,
} from "../../../api/api";
import {
  useGetReimbursementAttachments,
  useGetReimbursementsLifecycle,
} from "../../../api/api.get";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { IReimbursement, IReimbursementAttachment } from "../../../types/types";
import { reimbursementCategories } from "../../../utils/reimbursements";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { useDeleteReimbursementAttachment } from "../../../api/api.delete";
import { toSQL } from "../../../utils/date.utils";

export default function EditReimbursementModal({
  open,
  onCancel,
  reimbursement,
}: {
  reimbursement?: IReimbursement;
  open: boolean;
  onCancel: () => void;
}) {
  const { id } = useParams();
  const [messageApi, contextHolder] = message.useMessage();

  const [changeDateMode, setChangeDateMode] = useState<boolean>(false);
  const { notifySuccess, notifyError } = useNotifications({
    title: "Success",
    subtitle: "Reimbursement updated successfully.",
  });
  const { data: attachments, refetch: refetchAttachments } =
    useGetReimbursementAttachments(id, reimbursement?.id);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { data: lifecycle } = useGetReimbursementsLifecycle(
    id,
    reimbursement?.id,
  );

  const { mutateAsync, isPending } = useUpdateReimbursement(
    id,
    reimbursement?.id,
  );
  const {
    mutateAsync: createAttachmentAsync,
    isPending: isAttachmentUploadPending,
  } = useCreateAttachmentToReimbursement(id);
  const { mutateAsync: deleteAttachmentAsync } =
    useDeleteReimbursementAttachment(id, reimbursement?.id);

  const handleDeleteAttachment = async () => {
    deleteAttachmentAsync({ attachmentId: attachments?.[0]?.id })
      .then(() => {
        refetchAttachments();
      })
      .catch(() => {
        messageApi.open({
          type: "error",
          content: "Sorry! Please try again",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      category: reimbursement?.category ?? "",
      amount: reimbursement?.amount ?? 0,
      expenseDate: reimbursement?.expense_date ?? "",
      notes: reimbursement?.notes ?? "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      category: Yup.string().required("Please enter name"),
      expenseDate: Yup.string().required("Please select date"),
      amount: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid salary")
        .required("Please enter amount"),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        ...values,
        status: "IN_REVIEW",
        amount: +values.amount,
        expenseDate: toSQL(formik.values.expenseDate),
      })
        .then(() => {
          if (fileList.length > 0) {
            createAttachmentAsync({
              reimbursementId: reimbursement?.id,
              attachment: fileList[0],
            })
              .then(() => {
                notifySuccess();
                onCancel();
              })
              .catch((err) => {
                console.error(err);
                notifyError();
              });
          } else {
            formik.resetForm();
            notifySuccess();
            onCancel();
          }
        })
        .catch(console.error);
    },
  });

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  const columns = [
    {
      title: "Comments",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => <span>{status.replace("_", " ")}</span>,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (value: string) => (
        <span>{moment.utc(value).local().format("DD MMM YYYY h:mm A")}</span>
      ),
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      {contextHolder}
      <Modal
        title="Edit Reimbursement"
        open={open}
        onOk={formik.submitForm}
        onCancel={onCancel}
        okText="Update"
        okButtonProps={{
          loading: isPending || isAttachmentUploadPending,
          disabled: reimbursement?.status === "APPROVED",
        }}
      >
        <Flex gap={10} vertical style={{ padding: 10 }}>
          <Flex gap={10} vertical>
            <strong>Category</strong>
            <Select
              showSearch
              style={{ minWidth: 200 }}
              placeholder="Select a category"
              optionFilterProp="label"
              onChange={(value, option) =>
                formik.setFieldValue("category", (option as any).label)
              }
              value={formik.values.category}
              options={reimbursementCategories}
            />
          </Flex>

          <div>
            <strong>Amount</strong>
            <Input placeholder="Amount" {...formik.getFieldProps("amount")} />
            <ErrorPanel message={formik.errors.amount} />
          </div>

          <div className="divColumn">
            <strong>Expense Date</strong>
            <div className="divFlex divAlignItemsOnly">
              {!changeDateMode && (
                <>
                  <span className="mr-10">
                    {moment(formik.values.expenseDate).format("DD MMM YYYY")}
                  </span>
                  <Button type="link" onClick={() => setChangeDateMode(true)}>
                    Change
                  </Button>
                </>
              )}
              {changeDateMode && (
                <DatePicker
                  placeholder="Date"
                  onChange={(value) =>
                    formik.setFieldValue("expenseDate", value)
                  }
                />
              )}
            </div>

            <ErrorPanel message={formik.errors.expenseDate} />
          </div>

          {attachments && attachments.length > 0 && (
            <div className="divCenterAlign my-20">
              {attachments &&
                attachments.map((a: IReimbursementAttachment) => (
                  <img
                    alt="reimbursement"
                    src={`data:image/png;base64, ${a.imageData}`}
                    style={{ width: 200, height: "auto" }}
                  />
                ))}

              {reimbursement?.status !== "APPROVED" && (
                <div className="my-10">
                  <Button
                    type="link"
                    onClick={handleDeleteAttachment}
                    danger
                    icon={<DeleteOutlined />}
                  >
                    Remove
                  </Button>
                </div>
              )}
            </div>
          )}

          <Upload
            {...props}
            maxCount={1}
            accept="image/png,image/jpeg,application/pdf"
          >
            <Button disabled={fileList.length > 0} icon={<UploadOutlined />}>
              Click to Upload
            </Button>
          </Upload>

          {reimbursement?.status === "APPROVED" && (
            <Alert type="success" message="This reimbursement is approved." />
          )}
          {reimbursement?.status === "REJECTED" && (
            <Alert type="error" message="Rejected." />
          )}

          <Table dataSource={lifecycle} columns={columns} />
        </Flex>
      </Modal>
    </form>
  );
}
