import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Flex,
  Image,
  Input,
  Row,
  Skeleton,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import Title from "antd/es/typography/Title";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateProductImage } from "../../../api/api";
import { useDeleteProductImage } from "../../../api/api.delete";
import { useGetProductById, useGetProductImages } from "../../../api/api.get";
import {
  useUpdateProduct,
  useUpdateProductCover,
} from "../../../api/api.update";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { useCurrency } from "../../../hooks/useCurrency";
import { IProduct, IProductImage } from "../../../types/types";
import { getBase64 } from "../../../utils/file.utils";

export default function ProductsEditPage() {
  const { id, pid } = useParams();
  const { currency } = useCurrency();
  const navigate = useNavigate();

  const { notifySuccess, notification, notifyError } = useNotifications({
    title: "Success",
    subtitle: "Product created successfully.",
  });

  const { mutateAsync, isPending } = useUpdateProduct(id, pid);

  const { data: product, isLoading } = useGetProductById(id, pid);

  const formik = useFormik({
    initialValues: {
      title: product?.title ?? "",
      description: product?.description ?? "",
      amount: product?.price?.value ?? 0,
      externalId: product?.externalId,
      sku: product?.sku,
      quantity: product?.quantity ?? 0,
      salesTaxExcluded: product?.salesTaxExcluded,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      title: Yup.string().required("Please enter name"),
      description: Yup.string(),
      amount: Yup.string()
        .matches(
          /^(0|[1-9]\d*)(\.\d{2})?$/,
          "Please enter valid amount upto 2 decimals.",
        )
        .required("Please enter amount"),
      externalId: Yup.string(),
      quantity: Yup.number().typeError("Please enter valid number"),
      sku: Yup.string(),
      salesTaxExcluded: Yup.bool(),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        ...values,
        quantity: +values.quantity,
        price: {
          currency: "USD",
          value: +values.amount,
        },
      })
        .then(() => {
          formik.resetForm();
          notifySuccess();
        })
        .catch(() => notifyError());
    },
  });

  return (
    <div className="body p-40 flex-1">
      {notification}

      <div className="divCenterAlign">
        <Title level={3}>Edit Product</Title>
      </div>

      <Flex gap={50} vertical>
        <Skeleton loading={isLoading}>
          <form onSubmit={formik.handleSubmit} className="card p-40">
            <Flex gap={30} vertical style={{ padding: 20 }}>
              <div className="divRight">
                <Button
                  type="link"
                  onClick={() => navigate(`/organizations/${id}/products`)}
                >
                  Back
                </Button>
                <Button
                  loading={isPending}
                  onClick={formik.submitForm}
                  type="primary"
                >
                  Save
                </Button>
              </div>

              <div>
                <h4>Name</h4>
                <Input placeholder="Name" {...formik.getFieldProps("title")} />
                <ErrorPanel message={formik.errors.title} />
              </div>
              <div>
                <h4>Decription</h4>

                <Input
                  placeholder="Decription"
                  {...formik.getFieldProps("description")}
                />
                <ErrorPanel message={formik.errors.description} />
              </div>

              <div>
                <h4>Amount</h4>
                <Input
                  prefix={currency}
                  placeholder="Price"
                  {...formik.getFieldProps("amount")}
                />
                <ErrorPanel message={formik.errors.amount} />
              </div>

              <div>
                <h4>Available Quantity</h4>
                <Input
                  placeholder="BarCode Id"
                  {...formik.getFieldProps("quantity")}
                />
                <ErrorPanel message={formik.errors.quantity} />
              </div>

              <div>
                <h4>Barcode</h4>
                <Input
                  maxLength={100}
                  placeholder="BarCode Id"
                  {...formik.getFieldProps("externalId")}
                />
              </div>

              <div>
                <h4>SKU</h4>
                <Input
                  maxLength={100}
                  placeholder="BarCode Id"
                  {...formik.getFieldProps("sku")}
                />
              </div>

              <Checkbox
                onChange={(e) =>
                  formik.setFieldValue("salesTaxExcluded", e.target.checked)
                }
                checked={formik.values.salesTaxExcluded}
              >
                <h4 className="">Excluded from Sales Tax?</h4>
              </Checkbox>
            </Flex>
          </form>
        </Skeleton>

        <ProductImageSection product={product} />
      </Flex>
    </div>
  );
}

function ProductImageSection({ product }: { product?: IProduct }) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { notifySuccess, notification, notifyError } = useNotifications({
    title: "Success",
    subtitle: "Image uploaded successfully.",
  });
  const { id, pid } = useParams();

  const { data: images } = useGetProductImages(id, pid);

  const { mutateAsync, isPending } = useCreateProductImage(id, pid);

  const {
    mutateAsync: updateCoverImageAsync,
    isPending: isUpdateCoverImagePending,
  } = useUpdateProductCover(id, pid);

  const { mutateAsync: deleteImageAsync } = useDeleteProductImage(id, pid);

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const temp = [...fileList];
      temp.push(file);

      setFileList(temp);

      if (file.size > 10045377) {
      }
      return false;
    },
    fileList,
  };

  const handleUpload = () => {
    if (fileList.length > 0) {
      getBase64(fileList[0]).then((image) => {
        if (image) {
          mutateAsync({ image })
            .then(() => {
              setFileList([]);
              notifySuccess();
            })
            .catch(() => {
              notifyError();
            });
        } else {
          notifyError();
        }
      });
    }
  };

  const handleDelete = (imageId: string) => {
    deleteImageAsync({ imageId }).catch(() => notifyError());
  };

  const handleUpdateCover = (imageId: string) => {
    updateCoverImageAsync({ imageId }).catch(() => notifyError());
  };

  return (
    <form onSubmit={() => {}} className="card p-40">
      {notification}
      <div className="divCenterAlign">
        <Title level={3}>Images</Title>
      </div>

      <Flex gap={30} vertical style={{ padding: 20 }}>
        <div className="divSpread my-40">
          <span className="infoMsg">You can add upto 5 images per product</span>

          <Flex gap={10}>
            <Upload
              {...props}
              multiple={true}
              maxCount={5}
              accept="image/png,image/jpeg"
            >
              <Button
                disabled={fileList.length > 0 || fileList.length >= 5}
                icon={<UploadOutlined />}
              >
                Upload Image
              </Button>
            </Upload>

            <Button
              disabled={fileList.length === 0 || isUpdateCoverImagePending}
              onClick={handleUpload}
              type="primary"
              loading={isPending}
            >
              {" "}
              Upload
            </Button>
          </Flex>
        </div>

        <div className="">
          <Row gutter={[30, 30]}>
            {images?.map((image: IProductImage) => (
              <div className="mx-20 divColumn">
                <div className="my-10 divRight">
                  <DeleteOutlined
                    style={{ color: "red" }}
                    onClick={() => handleDelete(image._id)}
                  />
                </div>

                <Image width={200} src={image.image} preview={false} />
                <div className="divCenterAlign my-5">
                  {product?.coverId !== image._id && (
                    <Button
                      type="link"
                      onClick={() => handleUpdateCover(image._id)}
                    >
                      Cover
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </Row>
        </div>
      </Flex>
    </form>
  );
}
