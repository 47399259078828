import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Table, Typography } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDeleteOrgReimbursement } from "../../../api/api.delete";
import {
  useGetProfileOnOrganization,
  useGetReimbursements,
} from "../../../api/api.get";
import { IReimbursement } from "../../../types/types";
import { getReimbursementLabel } from "../../../utils/reimbursements";
import OrganizationDate from "../organization-date";
import AddReimbursementModal from "./add-reimbursement-modal";
import EditReimbursementModal from "./edit-reimbursement-modal";
import ViewReimbursementModal from "./view-reimbursements-modal";

const { Title } = Typography;

export default function ReimbursementsHomePage() {
  const { id } = useParams();
  const [date, setDate] = useState(moment());
  const { data: profile } = useGetProfileOnOrganization(id);

  const { data: reimbursements, refetch } = useGetReimbursements(
    id,
    date.startOf("month").format("YYYY-MM-DD"),
    date.endOf("month").format("YYYY-MM-DD"),
  );
  const [viewReimbursementObj, setViewReimbursementObj] =
    useState<IReimbursement>();

  const [editReimbursementObj, setEditReimbursementObj] =
    useState<IReimbursement>();

  const { mutateAsync: deleteAsync } = useDeleteOrgReimbursement(id!!);
  const [isAdd, setIsAdd] = useState<boolean>(false);

  const handleView = (row: any) => {
    setViewReimbursementObj(row);
  };

  const handleEdit = (row: any) => {
    setEditReimbursementObj(row);
  };

  const handleDateChange = (dateString: string) => {
    setDate(moment(dateString, "MMM YYYY"));
  };

  useEffect(() => {
    if (date) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const handleDelete = (row: any) => {
    deleteAsync({ reimbursementId: row.id })
      .then(() => refetch())
      .catch(console.error);
  };

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
      },
      {
        title: "Date",
        dataIndex: "expense_date",
        key: "expense_date",
        render: (value: string) => (
          <span>{moment(value).format("DD MMM YYYY")}</span>
        ),
      },

      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        width: 100,
        render: (value: string) => (
          <span style={{ color: value === "REJECTED" ? "red" : "black" }}>
            {getReimbursementLabel(value)}
          </span>
        ),
      },
      {
        title: "Last Updated",
        dataIndex: "last_updated",
        key: "last_updated",
        render: (value: string) => (
          <span>{moment(value).format("DD MMM YYYY")}</span>
        ),
      },
    ];

    let roleSpecificColumns: any[] = [];

    if (profile) {
      roleSpecificColumns =
        profile?.type === "admin"
          ? [
              {
                title: "",
                key: "operationView",
                width: 100,
                render: (row: any) => (
                  <Button type="link" onClick={() => handleView(row)}>
                    View
                  </Button>
                ),
              },
            ]
          : [
              {
                title: "",
                key: "operationEdit",
                width: 100,
                render: (row: any) => (
                  <Button type="link" onClick={() => handleEdit(row)}>
                    Edit
                  </Button>
                ),
              },
            ];
    }

    const deleteColumn = {
      title: "",
      key: "operationDelete",
      width: 100,
      render: (row: any) => (
        <Popconfirm
          icon={<DeleteOutlined />}
          title="Delete"
          description="Are you sure to delete this investment?"
          onConfirm={() => handleDelete(row)}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" disabled={row.status === "APPROVED"} danger>
            Delete
          </Button>
        </Popconfirm>
      ),
    };
    return [...defaultColumns, ...roleSpecificColumns, deleteColumn];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <div
      style={{ marginBottom: 100 }}
      className="card divColumn mx-40 my-40 flex-1"
    >
      <AddReimbursementModal open={isAdd} onCancel={() => setIsAdd(false)} />
      <EditReimbursementModal
        open={!!editReimbursementObj}
        onCancel={() => setEditReimbursementObj(undefined)}
        reimbursement={editReimbursementObj}
      />

      <ViewReimbursementModal
        open={!!viewReimbursementObj}
        onCancel={() => setViewReimbursementObj(undefined)}
        reimbursement={viewReimbursementObj}
      />
      <div className="divCenterAlign">
        <OrganizationDate onChange={handleDateChange} />
      </div>
      <div className="divSpread divAlignItemsOnly mt-40">
        <Title level={3}>Reimbursements</Title>

        {profile?.type !== "admin" && (
          <Button type="primary" onClick={() => setIsAdd(!isAdd)}>
            Add
          </Button>
        )}
      </div>
      <Table dataSource={reimbursements} columns={columns} />
    </div>
  );
}
