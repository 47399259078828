import { Avatar, Flex } from "antd";
import { useGetMyUserDetailsFromAuthBESession } from "../../api/api.get";
import { capitalize } from "../../utils/functions";
import { EXHUT_BIZ_LOCAL_STORAGE_TOKEN } from "../../utils/constants";

export default function Profile() {
  const { data: exhutProfile } = useGetMyUserDetailsFromAuthBESession();

  return (
    <div className="w-100 my-40">
      <div className={`divCenterAlign divColumn p-32`}>
        <div className="">
          <div className=" my-0">
            <Avatar
              style={{ width: 56, height: 56 }}
              src={"/assets/icons/man.svg"}
            />
          </div>

          <div className="card mt-40" style={{ minWidth: 250 }}>
            <Flex vertical className="mb-40">
              <h4>First Name</h4>
              <span>{capitalize(exhutProfile?.firstName)}</span>
            </Flex>

            <Flex vertical className="mb-40">
              <h4>Last Name</h4>
              <span>{capitalize(exhutProfile?.lastName)}</span>
            </Flex>

            <Flex vertical className="mb-40">
              <h4>Email</h4>
              <span>{exhutProfile?.email}</span>
            </Flex>

            <Flex vertical className="mb-40">
              <h4>Phone</h4>
              <span>{exhutProfile?.phone}</span>
            </Flex>

            <Flex vertical className="mb-40">
              <h4>Street 1</h4>
              <span>{capitalize(exhutProfile?.street1)}</span>
            </Flex>

            <Flex vertical className="mb-40">
              <h4>Street 2</h4>
              <span>{capitalize(exhutProfile?.street2)}</span>
            </Flex>

            <Flex vertical className="mb-40">
              <h4>City</h4>
              <span>{capitalize(exhutProfile?.city)}</span>
            </Flex>

            <Flex vertical className="mb-40">
              <h4>State</h4>
              <span>{capitalize(exhutProfile?.state)}</span>
            </Flex>

            <Flex vertical className="mb-40">
              <h4>Country</h4>
              <span>{exhutProfile?.countryCode}</span>
            </Flex>
            <Flex vertical className="mb-40">
              <h4>Date Of Birth</h4>
              <span>{exhutProfile?.dob}</span>
            </Flex>
          </div>
          <div className="my-40">
            <a
              href={`https://hub.expensehut.com/settings/profile?token=${localStorage.getItem(EXHUT_BIZ_LOCAL_STORAGE_TOKEN)}`}
              target="_blank"
              rel="noreferrer"
            >
              Update
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
