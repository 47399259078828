import { Button, message, Popconfirm, Table, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDeleteOrgInvestment } from "../../../api/api.delete";
import { useGetInvestments } from "../../../api/api.get";
import { IOrganizationInvestment } from "../../../types/types";
import OrganizationDate from "../organization-date";
import AddInvestmentModal from "./add-investment-modal";
import ViewInvestmentModal from "./view-investment-modal";

const { Title } = Typography;

export default function OrganizationInvestmentsHomePage() {
  const { id } = useParams();
  const [date, setDate] = useState(moment());

  const { data: investments, refetch } = useGetInvestments(
    id,
    date.startOf("M").format("YYYY-MM-DD"),
    date.endOf("M").format("YYYY-MM-DD"),
  );

  const { mutateAsync: deleteAsync } = useDeleteOrgInvestment(id!!);
  const [isAdd, setIsAdd] = useState<boolean>(false);

  const [viewableInvestmentObj, setViewableInvestmentObj] =
    useState<IOrganizationInvestment>();

  const handleView = (row: any) => {
    setViewableInvestmentObj(row);
  };

  const handleDateChange = (dateString: string) => {
    setDate(moment(dateString, "MMM YYYY"));
  };

  useEffect(() => {
    if (date) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const handleDelete = (row: any) => {
    deleteAsync({ investmentId: row.id })
      .then(() => {
        message.success("Investment successfully deleted.");
        refetch();
      })
      .catch(console.error);
  };

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Date",
      dataIndex: "investmentDate",
      key: "investmentDate",
      render: (value: string) => (
        <span>{moment(value).format("DD MMM YYYY")}</span>
      ),
    },
    {
      title: "",
      key: "operationView",
      width: 100,
      render: (row: any) => (
        <Button type="link" onClick={() => handleView(row)}>
          Edit
        </Button>
      ),
    },
    {
      title: "",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Popconfirm
          title="Delete"
          description="Are you sure to delete this investment?"
          onConfirm={() => handleDelete(row)}
          okText="Yes"
          cancelText="No"
        >
          <Button danger type="text" color="danger">
            Delete
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div
      style={{ marginBottom: 100 }}
      className="card divColumn mx-40 my-40 flex-1"
    >
      <AddInvestmentModal open={isAdd} onCancel={() => setIsAdd(false)} />
      <ViewInvestmentModal
        open={!!viewableInvestmentObj}
        onCancel={() => setViewableInvestmentObj(undefined)}
        investment={viewableInvestmentObj}
      />
      <div className="divCenterAlign mb-40">
        <OrganizationDate onChange={handleDateChange} />
      </div>
      <div className="divSpread divAlignItemsOnly m-40">
        <Title level={3}>Investments</Title>

        <Button type="primary" onClick={() => setIsAdd(!isAdd)}>
          Add
        </Button>
      </div>
      <Title level={5}>Add investments made on your business.</Title>
      <Table dataSource={investments} columns={columns} />
    </div>
  );
}
