import { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import LogoComponent from "../components/leftpane/items/logo.component";
import { useAuthContext } from "../providers/auth.provider";
import LeftPaneItems from "./leftpane/items/leftpane.items";

export default function MobileHeader() {
  const { user, signout } = useAuthContext();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const orgId = useMemo(() => {
    const matches = pathname.match(/(organizations\/)\d+/);
    return matches?.[0].replace("organizations/", "");
  }, [pathname]);

  const handleClick = (key: string) => {
    setAnchorEl(null);
    if (key === "whatis") {
      window.location.href = "https://www.expensehut.com";
    } else if (key === "settings") {
      navigate(`/organizations/${orgId}/settings`);
    } else if (key === "payroll") {
      navigate(`/organizations/${orgId}/payroll`);
    } else if (key === "change-password") {
      navigate("/settings/change-password");
    } else if (key === "logout") {
      signout();
    } else if (key === "login") {
      navigate("/login");
    }
  };

  return (
    <div className="bg-purple">
      <div className="divSpread divAlignItemsOnly">
        <LogoComponent isAuthenticated={!!user} />
        <LeftPaneItems
          anchorEl={anchorEl}
          open={!!anchorEl}
          onPostClick={handleClick}
          user={user}
        />
      </div>
    </div>
  );
}
