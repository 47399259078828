import { HourglassTwoTone } from "@ant-design/icons";
import { Calendar, CalendarProps, Col, Row, Select, Skeleton } from "antd";
import dayjs, { Dayjs } from "dayjs";
import dayLocaleData from "dayjs/plugin/localeData";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetTimeSheets, useGetTimeSheetsTotal } from "../../../api/api.get";
import ViewTimeSheetModal from "./view-timesheet.modal";

dayjs.extend(dayLocaleData);

export default function TimeSheetsHomePage() {
  const { id } = useParams();
  const [date, setDate] = useState(dayjs());

  const {
    data: timesheets,
    refetch,
    isLoading,
  } = useGetTimeSheets(
    id,
    date.startOf("month").format("YYYY-MM-DD"),
    date.endOf("month").format("YYYY-MM-DD"),
  );

  const {
    data: totalData,
    refetch: refetchTotal,
    isLoading: isTotalLoading,
  } = useGetTimeSheetsTotal(
    id,
    date.startOf("month").format("YYYY-MM-DD"),
    date.endOf("month").format("YYYY-MM-DD"),
  );

  useEffect(() => {
    if (date) {
      refetch();
      refetchTotal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const dateCellRender = (current: Dayjs) => {
    const total =
      timesheets
        ?.filter((t) => t.timeSheetDate === current.format("YYYY-MM-DD"))
        .reduce((acc, timesheet) => acc + timesheet.hours, 0) ?? 0;
    if (total > 0) {
      return (
        <div>
          <h2>{total}</h2>
        </div>
      );
    }
    return null;
  };

  const cellRender: CalendarProps<Dayjs>["cellRender"] = (current, info) => {
    return dateCellRender(current);
  };

  const [viewableDate, setViewableDate] = useState<Dayjs>();

  return (
    <div style={{ marginBottom: 100 }} className="p-40 w-100 divColumn mx-40">
      <h3>Timesheets</h3>
      <ViewTimeSheetModal
        day={viewableDate}
        open={!!viewableDate}
        onCancel={() => setViewableDate(undefined)}
      />

      <Skeleton loading={isLoading || isTotalLoading}>
        <Calendar
          cellRender={cellRender}
          onSelect={(day, { source }) => {
            if (source === "date") {
              setViewableDate(day);
            }
          }}
          onChange={(date) => setDate(date)}
          headerRender={({ value, type, onChange, onTypeChange }) => {
            const start = 0;
            const end = 12;
            const monthOptions = [];

            let current = value.clone();
            const localeData = value.localeData();
            const months = [];
            for (let i = 0; i < 12; i++) {
              current = current.month(i);
              months.push(localeData.monthsShort(current));
            }

            for (let i = start; i < end; i++) {
              monthOptions.push(
                <Select.Option key={i} value={i} className="month-item">
                  {months[i]}
                </Select.Option>,
              );
            }

            const year = value.year();
            const month = value.month();
            const options = [];
            for (let i = year - 1; i < year + 2; i += 1) {
              options.push(
                <Select.Option key={i} value={i} className="year-item">
                  {i}
                </Select.Option>,
              );
            }
            return (
              <div
                className="w-100 p-10 divRight divAlignItemsOnly mb-20"
                style={{ backgroundColor: "", color: "" }}
              >
                <h1 className="divFlex divAlignItemsOnly mx-20">
                  <HourglassTwoTone />
                  <strong
                    style={{ fontWeight: "bold", fontSize: 30 }}
                    className="mx-10"
                  >
                    {totalData?.total ?? 0}
                  </strong>
                </h1>
                <Row gutter={8}>
                  <Col>
                    <Select
                      size="large"
                      popupMatchSelectWidth={false}
                      className="my-year-select"
                      value={year}
                      onChange={(newYear) => {
                        const now = value.clone().year(newYear);
                        onChange(now);
                      }}
                    >
                      {options}
                    </Select>
                  </Col>
                  <Col>
                    <Select
                      size="large"
                      popupMatchSelectWidth={false}
                      value={month}
                      onChange={(newMonth) => {
                        const now = value.clone().month(newMonth);
                        onChange(now);
                      }}
                    >
                      {monthOptions}
                    </Select>
                  </Col>
                </Row>
              </div>
            );
          }}
        />
      </Skeleton>
    </div>
  );
}
