import { InfoCircleOutlined } from "@ant-design/icons";
import { Alert } from "antd";

export default function NoAccessPage() {
  return (
    <div id="no-access-page" className="w-100" style={{ height: 600 }}>
      <div className="divCenterAlign" style={{ height: 200 }}>
        <Alert
          banner
          icon={<InfoCircleOutlined />}
          type="error"
          message="Sorry! You don't have access to this page."
        />
      </div>
    </div>
  );
}
