import { DeleteFilled } from "@ant-design/icons";
import {
  AutoComplete,
  AutoCompleteProps,
  Button,
  Divider,
  Flex,
  Input,
  List,
} from "antd";
import Alert from "antd/es/alert/Alert";
import { DefaultOptionType } from "antd/es/select";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import * as Yup from "yup";
import { ICreateInvoicePayload } from "../../../api/api";
import {
  useGetInvoiceById,
  useSearchProducts,
  useSearchProductsByMultipleIds,
} from "../../../api/api.get";
import { updateUpdateInvoice } from "../../../api/api.update";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { useCurrency } from "../../../hooks/useCurrency";
import { IProduct } from "../../../types/types";
import EditInvoicePayments from "./edit-invoice-payments";
import ViewInvoiceModal from "./view-invoice-modal";

export default function EditInvoice() {
  const { id, iid } = useParams();
  const { areaCode } = useCurrency();
  const { notifySuccess, notification, notifyError } = useNotifications({
    title: "Success",
    subtitle: "Payment created successfully.",
  });
  const [servicesSearchText, setServicesSearchText] = useState<string>("");
  const [debouncedSearchText] = useDebounce(servicesSearchText, 300);
  const [options, setOptions] = useState<AutoCompleteProps["options"]>([]);
  const [items, setItems] = useState<IProduct[]>([]);

  const { data } = useSearchProducts(id, debouncedSearchText);

  const { data: invoiceData, refetch: refetchInvoiceData } = useGetInvoiceById(
    id,
    iid,
  );

  const { data: searchByMulitpleProductsData } = useSearchProductsByMultipleIds(
    id,
    invoiceData?.products,
  );
  const [isPrintMode, setPrintMode] = useState<boolean>(false);

  const { mutateAsync, isPending } = updateUpdateInvoice(id, iid);

  const formik = useFormik({
    initialValues: {
      total: invoiceData?.total ?? 0,
      tax: invoiceData?.tax ?? "",
      customerFirstName: invoiceData?.customer?.firstName ?? "",
      customerLastName: invoiceData?.customer?.lastName ?? "",
      customerStreet1: invoiceData?.customer?.address?.street1 ?? "",
      customerStreet2: invoiceData?.customer?.address?.street2 ?? "",
      customerCity: invoiceData?.customer?.address?.city ?? "",
      customerState: invoiceData?.customer?.address?.state ?? "",
      customerCountryCode: invoiceData?.customer?.address?.countryCode ?? "",
      customerZipCode: invoiceData?.customer?.address?.zipCode ?? "",
      customerPhone: invoiceData?.customer?.phone ?? "",
      customerEmail: invoiceData?.customer?.email ?? "",
      products: invoiceData?.products,
      notes: invoiceData?.notes ?? "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      total: Yup.number(),
      products: Yup.array().required("Please add products or services"),
      tax: Yup.string()
        .matches(/^(0|[1-9]\d*)(\.\d+)?$/, "Please enter valid number")
        .required("Please enter amount"),
      customerPhone: Yup.string().matches(
        /^(0|[1-9]\d*)(\.\d+)?$/,
        "Please enter valid phone number",
      ),
      customerEmail: Yup.string(),
      customerZipCode: Yup.string().matches(
        /^(0|[1-9]\d*)(\.\d+)?$/,
        "Please enter valid zip code",
      ),
    }),
    onSubmit: async (values) => {
      const payload: ICreateInvoicePayload = {
        products: values.products ?? [],
        tax: +values.tax,
        total: values.total,
        notes: values.notes,
        customer: {
          firstName: values.customerFirstName,
          lastName: values.customerLastName,
          email: values.customerEmail,
          phone: values.customerPhone,
          address: {
            street1: values.customerStreet1,
            street2: values.customerStreet2,
            city: values.customerCity,
            state: values.customerState,
            countryCode: values.customerCountryCode,
            zipCode: values.customerZipCode,
          },
        },
      };

      mutateAsync(payload)
        .then(() => {
          formik.resetForm();
          notifySuccess();
        })
        .catch(() => {
          notifyError();
        });
    },
  });

  useEffect(() => {
    if (data) {
      setOptions(
        data.map((product: IProduct) => {
          return {
            value: product.title,
            name: product.title,
            id: product._id,
            price: product.price,
          };
        }),
      );
    }
  }, [data]);

  useEffect(() => {
    if (searchByMulitpleProductsData && invoiceData?.products) {
      const _items = [];
      // Invoice can have multiple same product ids but search api will return only one
      // So query for all product ids, then fetch product data.
      for (const id of invoiceData?.products) {
        const productData = searchByMulitpleProductsData.find(
          (p: IProduct) => p._id === id,
        );
        _items.push({
          _id: productData?._id ?? "",
          title: productData?.title ?? "",
          price: productData?.price,
          createdAt: productData?.createdAt ?? "",
        });
      }
      setItems(_items);
    }
  }, [searchByMulitpleProductsData, invoiceData?.products]);

  const handleAddItems = useCallback(
    (option: DefaultOptionType) => {
      if (options) {
        const temp = [...items];
        temp.push({
          _id: option.id,
          title: option.name ?? "",
          price: option.price,
          createdAt: option.createdAt,
        });
        setItems(temp);
        const ids: string[] = temp.map((p: IProduct) => p._id);
        formik.setFieldValue("products", ids);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options],
  );

  const handleDeleteItems = (_item: Partial<IProduct>) => {
    const temp = [...items];
    const index = temp.findIndex((item) => item._id === _item._id);
    if (index >= 0) {
      temp.splice(index, 1);
    }
    const ids: string[] = temp.map((p: IProduct) => p._id);
    formik.setFieldValue("products", ids);
    setItems(temp);
  };

  useEffect(() => {
    let itemTotal = items.reduce(
      (acc, item) => acc + (item.price?.value ?? 0),
      0,
    );
    if (+formik.values.tax > 0) {
      itemTotal += (+formik.values.tax / 100) * itemTotal;
    }
    formik.setFieldValue("total", itemTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, formik.values.tax]);

  const navigate = useNavigate();

  const paymentStatus = useMemo(() => {
    if (invoiceData && invoiceData.payments) {
      const total = invoiceData.payments.reduce(
        (acc, payment) => acc + payment.amount,
        0,
      );
      if (total > 0) {
        if (total >= invoiceData.total) {
          return "paid";
        } else {
          return "partial";
        }
      }
      return "unpaid";
    }
  }, [invoiceData]);

  const canInvoiceEdit = useMemo(() => {
    if (invoiceData?.payments) {
      return invoiceData.payments.length === 0;
    }
  }, [invoiceData?.payments]);

  return (
    <div style={{ marginBottom: 100 }} className="divColumn p-40">
      <ViewInvoiceModal
        open={isPrintMode}
        onCancel={() => setPrintMode(false)}
        invoice={invoiceData}
      />

      <div className="divSpread">
        <Button
          type="link"
          onClick={() => navigate(`/organizations/${id}/invoices`)}
        >
          Back
        </Button>

        <Flex gap={20}>
          <Button type="dashed" onClick={() => setPrintMode(true)}>
            Print
          </Button>

          <Button
            loading={isPending}
            onClick={formik.submitForm}
            type="primary"
            disabled={!canInvoiceEdit}
          >
            Save
          </Button>
        </Flex>
      </div>

      {!canInvoiceEdit && (
        <div className="divCenterAlign mt-40">
          <span className="error fancy">
            This invoice can no longer be edited since it has active payments.
          </span>
        </div>
      )}

      <div className="card mt-40">
        <div className="divSpread divAlignItemsOnly">
          <h1>Invoice </h1>
          <span className="infoMsg">#{invoiceData?.externalId}</span>
        </div>

        <Flex className="my-20 divSpread" gap={20}>
          <div>
            {paymentStatus === "paid" && (
              <Alert
                style={{
                  backgroundColor: "var(--exhut-total-green)",
                  color: "white",
                }}
                type={"success"}
                message="Paid"
              />
            )}
            {paymentStatus === "unpaid" && (
              <Alert type={"error"} message="UnPaid" />
            )}
            {paymentStatus === "partial" && (
              <Alert type={"warning"} message="Partial Payment" />
            )}
          </div>
        </Flex>

        <Divider />

        <div className="mt-80">
          <Flex gap={30} vertical>
            <div className="flex-1">
              <AutoComplete
                disabled={!canInvoiceEdit}
                size="large"
                options={options}
                style={{ width: 300 }}
                onSelect={(_, option) => handleAddItems(option)}
                onSearch={(text) => setServicesSearchText(text)}
                placeholder="Search services or products..."
                notFoundContent={"No results found."}
              />
            </div>

            <div className="flex-1">
              {items.length > 0 && (
                <List
                  itemLayout="horizontal"
                  dataSource={items}
                  bordered
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <DeleteFilled
                          onClick={() => handleDeleteItems(item)}
                          style={{ color: "var(--exhut-light-red)" }}
                        >
                          delete
                        </DeleteFilled>,
                      ]}
                    >
                      <List.Item.Meta
                        title={item.title}
                        description={"$" + item.price?.value}
                      />
                    </List.Item>
                  )}
                />
              )}
            </div>
          </Flex>
        </div>

        <Divider />

        <form onSubmit={formik.handleSubmit}>
          {notification}
          <Flex gap={30} vertical>
            <div>
              <h4>Tax</h4>
              <Input
                prefix={"%"}
                disabled={!canInvoiceEdit}
                placeholder="Taxes"
                {...formik.getFieldProps("tax")}
              />
              <ErrorPanel message={formik.errors.tax} />
            </div>

            <div>
              <span>Total</span>
              <h1> ${formik.values.total.toFixed(2)}</h1>
            </div>

            <div>
              <h4 className="mb-40">Customer Details</h4>

              <Flex gap={50}>
                <Flex gap={30} vertical>
                  <Flex gap={10} vertical>
                    <span>First Name</span>
                    <Input {...formik.getFieldProps("customerFirstName")} />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>Last Name</span>
                    <Input {...formik.getFieldProps("customerLastName")} />
                  </Flex>
                  <Flex gap={10} vertical>
                    <span>Email</span>
                    <Input {...formik.getFieldProps("customerEmail")} />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>Phone</span>
                    <Input
                      prefix={areaCode}
                      {...formik.getFieldProps("customerPhone")}
                    />
                    <ErrorPanel message={formik.errors.customerPhone} />
                  </Flex>
                </Flex>

                <Flex gap={30} vertical>
                  <Flex gap={10} vertical>
                    <span>Street 1</span>
                    <Input {...formik.getFieldProps("customerStreet1")} />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>Street 2</span>
                    <Input {...formik.getFieldProps("customerStreet2")} />
                  </Flex>
                  <Flex gap={10} vertical>
                    <span>City</span>
                    <Input {...formik.getFieldProps("customerCity")} />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>State</span>
                    <Input {...formik.getFieldProps("customerState")} />
                  </Flex>
                </Flex>

                <Flex gap={30} vertical>
                  <Flex gap={10} vertical>
                    <span>Country</span>
                    <Input {...formik.getFieldProps("customerCountryCode")} />
                  </Flex>

                  <Flex gap={10} vertical>
                    <span>Zip Code</span>
                    <Input {...formik.getFieldProps("customerZipCode")} />
                    <ErrorPanel message={formik.errors.customerZipCode} />
                  </Flex>
                </Flex>
              </Flex>
            </div>

            <div>
              <h4>Notes</h4>

              <Input.TextArea
                placeholder="Notes"
                {...formik.getFieldProps("notes")}
              />
            </div>
          </Flex>

          <EditInvoicePayments
            invoiceData={invoiceData}
            refetchInvoiceData={refetchInvoiceData}
          />
        </form>
      </div>
    </div>
  );
}
