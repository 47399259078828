import { Button, Descriptions, Divider } from "antd";
import moment from "moment";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetPayrollCyclesById,
  useGetPayrollSchedule,
} from "../../../api/api.get";
import AddPayrollBonusModal from "./add-payroll-bonus.modal";
import AddPayrollScheduleModal from "./add-payroll-schedule-modal";
import PayrollBonusesSection from "./cycles/bonuses-section";
import PayrollDetailsSection from "./cycles/payroll-details";

export default function PayrollCycleHomePage() {
  const { id, pid: cycleId } = useParams();
  const navigate = useNavigate();

  const { data: schedule } = useGetPayrollSchedule(id);

  const { data: cycleData } = useGetPayrollCyclesById(id, cycleId);

  const [isAddSchedule, setIsAddSchedule] = useState<boolean>(false);
  const [isAddBonus, setIsAddBonus] = useState<boolean>(false);

  return (
    <div style={{ marginBottom: 100 }} className="body divColumn p-40 flex-1">
      <AddPayrollScheduleModal
        open={isAddSchedule}
        onCancel={() => setIsAddSchedule(false)}
      />

      <AddPayrollBonusModal
        open={isAddBonus}
        onCancel={() => setIsAddBonus(false)}
      />

      <div className="mb-40">
        <Button
          type="link"
          onClick={() => navigate(`/organizations/${id}/payroll-cycles`)}
        >
          Back
        </Button>
      </div>

      {schedule && (
        <Descriptions
          title="Payroll Cycle"
          bordered
          layout="vertical"
          items={[
            {
              key: "1",
              label: "Payroll Start Date",
              children: <div>{cycleData?.startDate}</div>,
            },
            {
              key: "2",
              label: "Payroll End Date",
              children: cycleData?.endDate,
            },
            {
              key: "3",
              label: "Created Date",
              children: moment(cycleData?.created_at).format("DD MMM YYYY"),
            },
          ]}
        />
      )}

      <Divider />

      <PayrollDetailsSection />

      <PayrollBonusesSection />
    </div>
  );
}
