import { Alert, Flex, Input, InputRef, Modal } from "antd";
import { useFormik } from "formik";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { useCreateRedemption } from "../../../api/api";
import { useGetRewardTotal, useSearchCustomers } from "../../../api/api.get";
import ErrorPanel from "../../../components/error.panel.component";
import { useNotifications } from "../../../components/notifications/notification";
import { getCountryPhoneCode } from "../../../utils/constants";

const { Search } = Input;

export default function AddRedeemModal({
  currency,
  open,
  onCancel,
  countryCode,
}: {
  currency: string;
  open: boolean;
  onCancel: () => void;
  countryCode?: string;
}) {
  const { id } = useParams();
  const textInputRef = useRef<InputRef>(null);
  const { mutateAsync } = useCreateRedemption(id);

  const { notifySuccess, notifyError } = useNotifications();

  const areaCode = getCountryPhoneCode(countryCode);

  useEffect(() => {
    if (textInputRef.current) {
      setTimeout(() => {
        textInputRef.current?.focus();
      }, 500);
    }
  }, [open]);

  const formik = useFormik({
    initialValues: {
      phone: "",
      rewards: 0,
    },
    validationSchema: Yup.object({
      phone: Yup.string().required("Please add customer phone number"),
      rewards: Yup.number()
        .typeError("Please enter valid number.")
        .required("Plese enter points to redeem"),
    }),
    onSubmit: async (values) => {
      mutateAsync({
        ...values,
        phone: `${areaCode}${values.phone}`,
        rewards: +values.rewards,
      })
        .then(() => {
          notifySuccess();
          formik.resetForm();
          onCancel();
        })
        .catch((err) => {
          if (err.response.data?.statusCode === 404) {
            notifyError();
          } else {
            notifyError();
          }
        });
    },
  });

  const {
    data: customerSearchData,
    refetch: searchCustomer,
    error,
  } = useSearchCustomers(areaCode + formik.values.phone, false);

  const { data: customerRewardsTotal, refetch: getCustomerRewardsTotal } =
    useGetRewardTotal(id, areaCode, formik.values.phone);

  useEffect(() => {
    if (customerSearchData) {
      getCustomerRewardsTotal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSearchData]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Redeem"
        open={open}
        onOk={formik.submitForm}
        okText={"Add"}
        onCancel={onCancel}
        okButtonProps={{
          disabled:
            !customerSearchData ||
            !!error ||
            customerRewardsTotal?.total === 0 ||
            (!!customerRewardsTotal?.total &&
              formik.values.rewards > (customerRewardsTotal?.total ?? 0)),
        }}
      >
        <Flex gap={20} vertical>
          <div>
            <p className="infoMsg">Customer phone number</p>

            <Search
              ref={textInputRef}
              addonBefore={areaCode}
              onSearch={() => searchCustomer()}
              style={{ width: "80%" }}
              {...formik.getFieldProps("phone")}
            />
            <ErrorPanel message={formik.errors.phone} />
          </div>

          {formik.values.phone && error && (
            <Alert
              showIcon
              closable
              type="error"
              description="Customer not found with this phone number."
            />
          )}

          {formik.values.phone && customerSearchData && (
            <Alert
              type="success"
              showIcon
              description={`Customer found : ${
                customerSearchData?.firstName ?? customerSearchData.email
              } ${customerSearchData?.lastName ?? ""}`}
            />
          )}

          {customerRewardsTotal && (
            <div className="my-0">
              <h3>Available To Redeem: {customerRewardsTotal.total}</h3>
            </div>
          )}

          {customerRewardsTotal?.total &&
            formik.values.rewards > (customerRewardsTotal?.total ?? 0) && (
              <Alert
                type="error"
                showIcon
                description={`Insufficient value. Please enter lesser number.`}
              />
            )}

          {customerRewardsTotal?.total === 0 && (
            <Alert
              type="error"
              showIcon
              description={`Customer has insufficient rewards to redeem.`}
            />
          )}

          {customerSearchData && (
            <>
              <div>
                <Input
                  placeholder="Enter rewards to redeem..."
                  {...formik.getFieldProps("rewards")}
                />
                <ErrorPanel message={formik.errors.rewards} />
              </div>
            </>
          )}
        </Flex>
      </Modal>
    </form>
  );
}
