import { BrowserRouter, Route, Routes } from "react-router-dom";
import MobileHeader from "./components/mobile.header.component";
import LandingPage from "./pages/landing.page";
import AuthProvider from "./providers/auth.provider";
import { AuthenticatedRoutes } from "./routes";
import "./styles/button.styles.css";
import FooterComponent from "./components/footer.component";
import RegisterPage from "./pages/register.page";
import LoginPage from "./pages/login.page";

const Main = () => {
  return (
    <div className={`app`}>
      <BrowserRouter>
        {/*
        Do not move auth provider from here.
        The Mobile header component needs this.
        */}
        <AuthProvider>
          <MobileHeader />
          <Routes>
            <Route path="/register/verify" element={<RegisterPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="*" element={<AuthenticatedRoutes />} />
          </Routes>
        </AuthProvider>
        <FooterComponent />
      </BrowserRouter>
    </div>
  );
};

export default Main;
