import { Button, Table, Typography } from "antd";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetOrganizationEmployees,
  useGetOrganizationEmployeesSalary,
  useGetOrganizationEmployeesSalaryTotal,
} from "../../../api/api.get";
import AddExpenseModal from "./add-employee-modal";

const { Title } = Typography;

export default function OrganizationEmployeesHomePage() {
  const { id } = useParams();
  const [date] = useState<Moment>(moment());
  const navigate = useNavigate();

  const { data: employees, isLoading } = useGetOrganizationEmployees(id);

  const { refetch: refetchSalaryTotal } =
    useGetOrganizationEmployeesSalaryTotal(
      id,
      date.startOf("M").format("YYYY-MM-DD"),
      date.endOf("M").format("YYYY-MM-DD"),
    );

  const { refetch: refetchSalary } = useGetOrganizationEmployeesSalary(
    id,
    date.startOf("M").format("YYYY-MM-DD"),
    date.endOf("M").format("YYYY-MM-DD"),
  );

  useEffect(() => {
    if (date) {
      refetchSalary();
      refetchSalaryTotal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const [isAdd, setIsAdd] = useState<boolean>(false);

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Salaried/Contractor",
      dataIndex: "employmentType",
      key: "employmentType",
      render: (value: string) => (
        <span>
          {value ? (value === "salaried" ? "Salaried" : "Contractor") : ""}
        </span>
      ),
    },
    {
      title: "Salary",
      dataIndex: "salary",
      key: "salary",
      render: (value: string) => <span>{value}</span>,
    },
    {
      title: "Start Date",
      dataIndex: "employmentStartDate",
      key: "employmentStartDate",
      render: (value: string) => (
        <span>{value ? moment(value).format("DD MMM YYYY") : ""}</span>
      ),
    },
    {
      title: "End Date",
      dataIndex: "employmentEndDate",
      key: "employmentEndDate",
      render: (value: string) => (
        <span>{value ? moment(value).format("DD MMM YYYY") : ""}</span>
      ),
    },
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (row: any) => (
        <Button
          type="link"
          onClick={() => navigate(`/organizations/${id}/employees/${row.id}`)}
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <div
      style={{ marginBottom: 100 }}
      className="card divColumn mx-40 my-20 flex-1"
    >
      <AddExpenseModal open={isAdd} onCancel={() => setIsAdd(false)} />

      <div className="divSpread divAlignItemsOnly mt-40">
        <Title level={3}>Employees</Title>

        <Button type="primary" onClick={() => setIsAdd(!isAdd)}>
          Add
        </Button>
      </div>

      <Table
        loading={isLoading}
        dataSource={employees}
        pagination={{ pageSize: 100 }}
        columns={columns}
      />
      <span className="infoMsg my-10">
        Note: Salary indicated is annual salary for fulltime employees and
        hourly rate for contractors
      </span>
    </div>
  );
}
